import React, { useMemo } from "react";

import { useNavigate } from "react-router";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Icon, Popover } from "@/app/components";
import { colors, Routes } from "@/app/constants";
import { normaliseDate } from "@/app/misc/helpers.ts";
import { standardMetrics } from "@/app/screens/metrics-extraction";
import { NumberRowWithFormat, RowWithCurrency, TableText } from "@/app/screens/opportunities/opportunity/components";

function getMetricLabel(s: string | undefined) {
  if (!s) return "";
  const standardMetricLabel = standardMetrics.find((metric) => metric.id === s)?.label;
  if (standardMetricLabel) return standardMetricLabel;

  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export const useMetrics = (company) => {
  const metrics = company.metrics?.nodes || [];

  const [maximised, setMaximised] = React.useState(false);

  const { dealroomEmployees, websiteTraffic } = useMemo(() => {
    const employeesObject = (company?.employeesChart || []).reduce((prev, curr) => {
      prev[curr.date.split("-").slice(0, -1).join("-")] = curr.value;
      return prev;
    }, []);
    const websiteTraffic = (company?.websiteTrafficChart || []).reduce((prev, curr) => {
      prev[curr.date.split("-").slice(0, -1).join("-")] = curr.value;
      return prev;
    }, {});
    return {
      dealroomEmployees: Object.entries(employeesObject).map(([key, value]) => ({
        x: key,
        y: Number(value),
        source: "Dealroom",
      })),
      websiteTraffic: Object.entries(websiteTraffic).map(([key, value]) => ({
        x: key,
        y: value,
        source: "Dealroom",
      })),
    };
  }, [company]);
  const { isLaptop } = useBreakpoints();
  const navigate = useNavigate();

  let legendScaleOptions = { domain: ["Manual-Entry"], range: [colors.primary.DEFAULT] };

  let allMetrics = metrics.reduce((acc, { data }) => {
    Object.keys(data)
      .filter((key) => key !== "asAtDate" && standardMetrics.find((metric) => metric.id === key))
      .forEach((key) => acc.add(key));
    return acc;
  }, new Set());

  allMetrics = sortSetByArray(
    allMetrics,
    standardMetrics.map(({ id }) => id),
  );

  const initialSelectedMetric =
    getMetricLabel(allMetrics?.[0]) || company?.employeesChart?.length
      ? "FTEs"
      : company?.websiteTrafficChart?.length
        ? "WebTraffic"
        : "FTE";

  const [selectedMetric, setSelectedMetric] = React.useState(initialSelectedMetric);

  const columns = [
    {
      id: "type",
      accessorKey: "Type",
      header: () => (
        <p
          className={
            "flex size-full w-[99%] cursor-default bg-white px-8 py-3 text-[14px] font-medium text-neutral-600"
          }
        >
          Type
        </p>
      ),
      size: isLaptop ? 200 : 130,
      cell: (info) => (
        <div
          className={cn(
            "group flex size-full w-[99%] cursor-default select-none items-center justify-start bg-white px-4 group-hover:bg-gray-50",
          )}
        >
          <p className={"cursor-default text-wrap break-words px-4 text-left text-[14px] font-medium text-neutral-600"}>
            {info.renderValue()}
          </p>
        </div>
      ),
    },
    ...([
      ...new Set(
        metrics.toSorted((a, b) => (a.asAtDate < b.asAtDate ? -1 : 1)).map(({ asAtDate }) => normaliseDate(asAtDate)),
      ),
    ].map((asAtDate) => {
      return {
        id: asAtDate,
        accessorKey: asAtDate,
        header: () => <p className={"py-3"}>{asAtDate}</p>,
        size: isLaptop ? 200 : 130,
        sort: false,
        cell: (info) => {
          const { note, type, formatInfo, value, format } = info.renderValue() || {};
          const { currencySymbol } = formatInfo || {};
          return (
            <div className={cn("flex w-full items-center justify-center py-3.5 pl-3 ")}>
              <Popover
                childrenContent={null}
                placement={"top"}
                childrenClassName={"-mt-2"}
                containerClassName={"flex  text-center "}
              >
                {!value && !type && <TableText text={value} containerClassName={"text-wrap break-all"} />}
                {format === "percentage" && <NumberRowWithFormat label={value} />}
                {format === "currency" && (
                  <RowWithCurrency currency={currencySymbol} label={value} unit={value / 1_000_000 > 1 ? "M" : ""} />
                )}
                {(format === "decimal" || format == "integer") && <NumberRowWithFormat label={value} suffix={""} />}
                {type === "string" && <TableText text={value} containerClassName={"text-wrap break-all"} />}
                {type === "boolean" && (
                  <TableText text={value ? "Yes" : "No"} className={"text-nowrap text-neutral-800"} />
                )}
              </Popover>
              {note && (
                <Popover
                  childrenContent={note}
                  placement={"top"}
                  childrenClassName={"-mt-2"}
                  containerClassName={"ml-1"}
                >
                  <Icon
                    type={"NotificationNote"}
                    className={cn("size-4 text-neutral-300", note ? "hover:text-neutral" : "")}
                  />
                </Popover>
              )}
            </div>
          );
        },
      };
    }) || []),
  ];

  const tabularData = [...allMetrics].map((metric) => {
    return {
      Type: getMetricLabel(metric),
      ...Object.fromEntries(
        metrics
          .toSorted((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
          .map(({ asAtDate, data }) => {
            // fill in data from Dealroom for FTES
            if (metric === "FTE" && !data[metric]) {
              return [
                normaliseDate(asAtDate),
                {
                  id: "FTE",
                  label: "FTEs",
                  type: "number",
                  format: "integer",
                  active: false,
                  note: "",
                  highlight: false,
                  quotation: "",
                  category: "standard",
                  source: "Manually Added",
                  value: dealroomEmployees.find(({ x }) => x === asAtDate?.slice(0, 7))?.y || undefined,
                  asAtDate,
                },
              ];
            }
            return [normaliseDate(asAtDate), data[metric] == null ? "N/A" : { ...data[metric], asAtDate }];
          })
          .filter(([, value]) => value !== "N/A"),
      ),
    };
  });
  let graphData = tabularData?.find((row) => row.Type === selectedMetric) || [];

  if (graphData) {
    graphData = [
      ...(Object.values(graphData) || []).reduce((acc, value) => {
        let suffix = "",
          prefix = "";
        if (value?.format === "percentage") suffix = "%";
        if (value?.format === "currency") prefix = currencyToSymbol(value?.formatInfo?.currencySymbol);
        if (typeof value === "string") return acc;

        return [...acc, { x: value.asAtDate?.slice(0, 10), y: value.value, source: "Manual-Entry", suffix, prefix }];
      }, []),
      ...(selectedMetric === "FTEs" ? dealroomEmployees : []),
      ...(selectedMetric === "WebTraffic" ? websiteTraffic : []),
    ].toSorted((a, b) => (a.x > b.x ? 1 : -1));

    if (
      (dealroomEmployees.length && selectedMetric === "FTEs") ||
      (websiteTraffic.length && selectedMetric === "WebTraffic")
    ) {
      legendScaleOptions = { domain: ["Manual-Entry", "Dealroom"], range: [colors.primary.DEFAULT, "#1677ff"] };
    }
  }

  const showEmpty = !(
    columns?.length > 1 ||
    tabularData?.length ||
    company?.employeesChart?.length ||
    company?.websiteTrafficChart?.length
  );

  const metricsSelectList = [
    ...(!tabularData?.find(({ Type }) => Type === "FTEs") && dealroomEmployees?.length
      ? [{ value: "FTEs", label: "FTEs" }]
      : []),
    ...tabularData.map(({ Type }) => ({ value: Type, label: Type })),
    ...(websiteTraffic?.length ? [{ value: "WebTraffic", label: "Web traffic" }] : []),
  ];

  return {
    columns,
    graphData,
    maximised,
    showEmpty,
    tabularData,
    setMaximised,
    selectedMetric,
    setSelectedMetric,
    legendScaleOptions,
    metricsSelectList,
    navigateToMetricsExtraction: () => {
      navigate(`${Routes.metricsExtraction}/new`, { state: { companyId: company.id } });
    },
    selectedIndex: metricsSelectList.findIndex((item) => item?.value === selectedMetric),
  };
};

function sortSetByArray(set, orderArray) {
  const setArray = Array.from(set);
  setArray.sort((a, b) => {
    const indexA = orderArray.indexOf(a);
    const indexB = orderArray.indexOf(b);

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    return 0;
  });

  return setArray;
}

function currencyToSymbol(currency: "USD" | "EUR" | "GBP" | string) {
  if (!currency) return "";
  switch (currency) {
    case "USD":
      return "$";
    case "EUR":
      return "€";
    case "GBP":
      return "£";
    default:
      return currency;
  }
}
