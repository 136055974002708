import { Modal } from "@/app/components";

export const OpportunityMaximise = ({ children, maximised, setMaximised }) => {
  if (!maximised || !children) return null;
  return (
    <>
      <Modal
        isOpen={maximised}
        showX={false}
        handleClose={() => setMaximised(false)}
        containerClassName={"z-30 "}
        bodyContainerClassName={"h-dvh w-dvw"}
        bodyClassName={
          "min-h-[100dvh] min-w-[100dvw] lg:min-h-[85dvh] lg:min-w-[90dvw] p-0 rounded-[0px] lg:rounded-[8px]"
        }
        bodyContentClassName={"px-0"}
      >
        {children}
      </Modal>
    </>
  );
};
