import { X } from "lucide-react";

import globalModalStore from "@/app/screens/modal/global-modal.store.ts";

type CloseModalProps = {
  onClosing?: () => void;
};
export const CloseModal = ({ onClosing }: CloseModalProps) => {
  return (
    <X
      className={"absolute right-5 mt-3 size-6 rounded-full p-0.5 text-neutral-500"}
      onClick={() => {
        globalModalStore.toggleModal();
        onClosing?.();
      }}
    />
  );
};
