import { useState } from "react";

import {
  SentimentState,
  useSentimentContext,
} from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-context.tsx";
import { SentimentAttribute } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-header.tsx";
import { useOpportunityContext } from "@/app/screens/opportunities/opportunity/opportunity-context.tsx";

export function useSentiment() {
  const { company } = useOpportunityContext();
  const { state, maximised, setMaximised } = useSentimentContext();
  const sentimentData =
    company?.sentiment?.nodes.filter(({ createdAt, participants, responses }) => {
      // the old sentiment forms have a different structure and we need to handle them differently
      if (createdAt < "2023-12-31") return responses?.length > 0;
      return !participants?.nodes?.every((item) => item.state === "DECLINED");
    }) || [];
  const showEmptyState =
    state === SentimentState.no_data ||
    !company ||
    !sentimentData?.length ||
    !sentimentData.some((data) => data.responses.length);

  return { maximised, setMaximised, company, sentimentData, showEmptyState };
}

export const sentimentAttributes: {
  value: SentimentAttribute;
  label: string;
}[] = [
  { label: "Excitement", value: "excitement_opinion_scale" },
  { label: "Vision", value: "vision_opinion_scale" },
  { label: "Market", value: "market_opinion_scale" },
  { label: "Product", value: "product_opinion_scale" },
  { label: "Team", value: "team_opinion_scale" },
  { label: "Fundraising", value: "fundraising_opinion_scale" },
  { label: "Timing", value: "timing_opinion_scale" },
  { label: "Fit", value: "fit_opinion_scale" },
  { label: "Opportunity", value: "opportunity_opinion_scale" },
  // { label: "Expected Return", value: "expected_return" }, // todo disabled in LOC-871 until implemented
];
