import { EmptySection } from "@/app/screens/opportunities/opportunity/components";
import { OpportunityMaximise } from "@/app/screens/opportunities/opportunity/components/maximise/maximise.tsx";
import { SentimentTable } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-table.tsx";
import { useSentiment } from "@/app/screens/opportunities/opportunity/components/sentiment/use-sentiment.tsx";

import { SentimentChart } from "./sentiment-chart.tsx";
import { SentimentContextProvider } from "./sentiment-context.tsx";

export const Sentiment = () => {
  return (
    <SentimentContextProvider>
      <SentimentContent />
    </SentimentContextProvider>
  );
};

const SentimentContent = () => {
  const { maximised, setMaximised, sentimentData, showEmptyState } = useSentiment();

  if (showEmptyState) {
    return <EmptySection text={"No Sentiment Forms Filled"} />;
  }

  return (
    <>
      <div className={"pointer-events-auto flex flex-col items-center justify-between rounded-md bg-white pb-3 pt-5"}>
        <SentimentChart sentimentData={sentimentData} />
        <SentimentTable sentimentData={sentimentData} />
      </div>
      <OpportunityMaximise setMaximised={setMaximised} maximised={maximised}>
        <SentimentChart sentimentData={sentimentData} />
      </OpportunityMaximise>
    </>
  );
};
