import { useBreakpoints } from "@/lib/hooks";

import { CompanyImg, Icon } from "@/app/components";
import { lineColors } from "@/app/components/charts/sentiment-history.tsx";
import { Select } from "@/app/components/input";
import { Company } from "@/app/hooks";
import { restrictedFunds } from "@/app/misc/filters.ts";
import { AddNewResponsiveModal } from "@/app/screens/modal";
import { SearchCompany } from "@/app/screens/modal/actions/add-sentiment/components";
import { useSentimentContext } from "@/app/screens/opportunities/opportunity/components/sentiment/sentiment-context.tsx";
import { sentimentAttributes } from "@/app/screens/opportunities/opportunity/components/sentiment/use-sentiment.tsx";
import modalStore from "@/app/stores/modal.store";

export type SentimentAttribute =
  | "excitement_opinion_scale"
  | "vision_opinion_scale"
  | "market_opinion_scale"
  | "product_opinion_scale"
  | "team_opinion_scale"
  | "fundraising_opinion_scale"
  | "timing_opinion_scale"
  | "fit_opinion_scale"
  | "opportunity_opinion_scale";

export type SentimentFieldSelectorProps = {
  value: SentimentAttribute;
  onChange: (value: SentimentAttribute) => void;
  selectedCompanies: Company[];
  setSelectedCompanies: (value: Company[]) => void;
  company: any;
};

export const SentimentHeader = ({
  company,
  onChange,
  value: selectedValue,
  selectedCompanies,
  setSelectedCompanies,
}: SentimentFieldSelectorProps) => {
  const { isLaptop } = useBreakpoints();
  const { dataAlignment, setDataAlignment, dashboardSelector, setDashboardSelector, maximised, setMaximised } =
    useSentimentContext();

  return (
    <div className={"flex w-full flex-col px-4 lg:px-6"}>
      <div className={"flex w-full items-center justify-between"}>
        <div className={"flex w-full items-center justify-between"}>
          <div className={"flex lg:w-[23%]"}>
            {maximised && (
              <Select<{ value: string; label: string }>
                className={
                  "mr-4 h-fit min-w-fit !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-300"
                }
                optionsClassName={"text-[14px] font-medium w-[100%] no-scrollbar w-[100%]"}
                inputClassName={"text-[14px] font-semibold mr-3"}
                buttonClassName={"px-3 py-2"}
                value={dashboardSelector.value}
                options={[
                  { value: "Time Series", label: "Time Series" },
                  { value: "Benchmark", label: "Benchmark" },
                ]}
                onChange={setDashboardSelector}
              />
            )}
            <Select<{ value: string; label: string }>
              prefixClassName={"text-[14px] font-semibold text-neutral-600"}
              prefix={isLaptop ? "Parameter:" : undefined}
              className={
                " min-w-fit !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-300"
              }
              inputClassName={"text-[14px] font-semibold mr-3"}
              buttonClassName={"px-3 py-2"}
              optionsClassName={"text-[14px] font-medium w-[100%] no-scrollbar"}
              value={selectedValue}
              options={sentimentAttributes}
              onChange={({ value }) => onChange(value)}
            />
          </div>

          {maximised && isLaptop && (
            <div className="ml-4 hidden min-w-[33%] flex-row gap-4 lg:flex">
              <SearchCompany
                clearAfterSelection={true}
                showEmptyState={false}
                iconType={"Search"}
                iconClassName={"text-neutral-600 size-4"}
                inputClassName={"text-[14px] font-semibold py-2 h-fit rounded-xs font-medium text-neutral"}
                searchBarClassName={"p-0 m-0 max-h-fit rounded-xs outline outline-neutral-200 border-0"}
                selectCompany={(company) => {
                  if (company) setSelectedCompanies((prevState) => [...prevState, company]);
                }}
                placeholder={"Compare with company..."}
              />
            </div>
          )}
          {!isLaptop && (
            <p className={"flex justify-between"}>
              <a
                href={"#sentiment"}
                onClick={() => setMaximised((prevState) => !prevState)}
                className={
                  "group flex select-none items-center rounded-[4px] px-3 py-2 outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100"
                }
              >
                <Icon
                  type={maximised ? "Minimise" : "Maximise"}
                  className={"size-4.5 group-hover:text-neutral-800 lg:mr-2"}
                />
                <p
                  className={
                    "hidden text-nowrap text-[14px] font-semibold text-neutral-900 group-hover:text-neutral-800 lg:flex"
                  }
                >
                  {maximised ? "Minimise" : "Maximise and compare"}
                </p>
              </a>
            </p>
          )}
          {maximised && isLaptop && (
            <a
              href={"#sentiment"}
              onClick={() => {
                setMaximised((prev) => !prev);
              }}
              className={
                "group flex select-none items-center rounded-[4px] px-3 py-2 outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100"
              }
            >
              <Icon
                type={maximised ? "Minimise" : "Maximise"}
                className={"size-4.5 group-hover:text-neutral-800 lg:mr-2"}
              />
              <p
                className={
                  "hidden text-nowrap text-[14px] font-semibold text-neutral-900 group-hover:text-neutral-800 lg:flex"
                }
              >
                {maximised ? "Minimise" : "Maximise and compare"}
              </p>
            </a>
          )}
        </div>

        {!maximised && isLaptop && (
          <div className={"flex items-center justify-center space-x-4"}>
            <a
              href={"#sentiment"}
              onClick={() => {
                setMaximised((prev: boolean) => !prev);
              }}
              className={
                "group flex select-none items-center rounded-[4px] px-3 py-2 outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100"
              }
            >
              <Icon
                type={maximised ? "Minimise" : "Maximise"}
                className={"size-4.5 group-hover:text-neutral-800 lg:mr-2"}
              />
              <p
                className={
                  "hidden text-nowrap text-[14px] font-semibold text-neutral-900 group-hover:text-neutral-800 lg:flex"
                }
              >
                {maximised ? "Minimise" : "Maximise and compare"}
              </p>
            </a>

            <div
              onClick={() => {
                modalStore.open(AddNewResponsiveModal, {
                  props: { company, sentimentOnly: true },
                });
              }}
              className={
                "group flex select-none items-center rounded-[4px] px-3 py-2 outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100"
              }
            >
              <Icon type={"Add"} className={"mr-2 size-4.5 group-hover:text-neutral-800"} />
              <p className={"text-nowrap text-[14px] font-semibold text-neutral-900 group-hover:text-neutral-800"}>
                Add sentiment
              </p>
            </div>
          </div>
        )}
      </div>
      {maximised && !isLaptop && (
        <SearchCompany
          clearAfterSelection={true}
          showEmptyState={false}
          iconType={"Search"}
          iconClassName={"text-neutral-600 size-4"}
          inputClassName={"text-[14px] font-semibold py-2 h-fit rounded-xs font-medium text-neutral"}
          searchBarClassName={"p-0 m-0 max-h-fit rounded-xs outline outline-neutral-200 border-0 my-4"}
          selectCompany={(company) => {
            if (company) setSelectedCompanies((prevState) => [...prevState, company]);
          }}
          placeholder={"Compare with company..."}
        />
      )}
      {maximised && selectedCompanies.length >= 2 && (
        <div className={"mt-0 flex items-center justify-between lg:mt-4"}>
          <div className={"no-scrollbar flex items-center gap-x-2 overflow-auto"}>
            {selectedCompanies?.map((company, idx) => {
              const bgGradient = `linear-gradient(160deg, ${lineColors[idx].from}, ${lineColors[idx].to})`;
              return (
                <div className="flex flex-row items-center rounded-[4px] bg-neutral-100 px-3 py-1.5" key={idx}>
                  <div className="mr-3 size-4 rounded-full" style={{ background: bgGradient }} />
                  <CompanyImg
                    className="mr-2 !h-6 !min-h-6 !w-6 !min-w-6 !rounded-[4px]"
                    imgClassName={"rounded-[2px] !ring-0"}
                    src={company.image}
                  />
                  <p className="mr-3 select-none whitespace-nowrap text-[14px] font-semibold text-neutral-900">
                    {company.name}
                  </p>
                  {idx !== 0 && (
                    <Icon
                      type={"X"}
                      onClick={() => {
                        if (idx === 0) return;
                        const newComps = selectedCompanies.toSpliced(idx, 1);
                        setSelectedCompanies(newComps);
                      }}
                      className={`size-5 cursor-pointer rounded-[2px] bg-neutral-200 p-0.5 hover:cursor-pointer hover:bg-neutral-100 hover:text-neutral-600`}
                      aria-hidden="true"
                    />
                  )}
                </div>
              );
            })}
          </div>

          {isLaptop && (
            <Select<{ value: string; label: string }>
              prefixClassName={"text-[14px] font-semibold text-neutral-600"}
              prefix={"Normalise:"}
              className={
                "h-fit min-w-fit !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-300"
              }
              inputClassName={"text-[14px] font-semibold mr-3"}
              buttonClassName={"px-3 py-2"}
              optionsClassName={"text-[14px] font-medium w-[100%] no-scrollbar"}
              value={dataAlignment.value}
              options={[
                { value: "Normal", label: "Normal" },
                { value: "Left Align", label: "Left Align" },
                { value: "Left-Right Align", label: "Left-Right Align" },
                // { value: "Right Align", label: "Right Align" },
              ]}
              onChange={setDataAlignment}
            />
          )}
        </div>
      )}
      {maximised && selectedCompanies.length >= 2 && !isLaptop && (
        <Select<{ value: string; label: string }>
          prefixClassName={"text-[14px] font-semibold text-neutral-600"}
          prefix={"Normalise:"}
          className={
            "my-4 h-fit min-w-fit !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-300"
          }
          inputClassName={"text-[14px] font-semibold mr-3"}
          buttonClassName={"px-3 py-2"}
          optionsClassName={"text-[14px] font-medium w-[100%] no-scrollbar"}
          value={dataAlignment.value}
          options={[
            { value: "Normal", label: "Normal" },
            { value: "Left Align", label: "Left Align" },
            { value: "Left-Right Align", label: "Left-Right Align" },
            // { value: "Right Align", label: "Right Align" },
          ]}
          onChange={setDataAlignment}
        />
      )}
    </div>
  );
};

export const SentimentBenchmarkHeader = ({ maximised, setMaximised }) => {
  const { isLaptop } = useBreakpoints();
  const {
    fundSelected,
    setFundSelected,
    dashboardSelector,
    setDashboardSelector,
    benchmarkAttribute,
    setBenchmarkAttribute,
  } = useSentimentContext();

  return (
    <div className={"flex w-full flex-col px-4 lg:px-6"}>
      <div className={"flex w-full items-center justify-between"}>
        <div className={"flex w-full items-center justify-between"}>
          <div className={"flex"}>
            {maximised && (
              <Select<{ value: string; label: string }>
                className={
                  "mr-4 h-fit min-w-fit !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-300"
                }
                inputClassName={"text-[14px] font-semibold mr-3"}
                buttonClassName={"px-3 py-2"}
                optionsClassName={"text-[14px] font-medium w-[100%] no-scrollbar"}
                value={dashboardSelector.value}
                options={[
                  { value: "Time Series", label: "Time Series" },
                  { value: "Benchmark", label: "Benchmark" },
                ]}
                onChange={setDashboardSelector}
              />
            )}
            <Select<{ value: string; label: string }>
              className={
                "mr-4 h-fit min-w-fit !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-300"
              }
              prefix={"Attribute:"}
              inputClassName={"text-[14px] font-semibold mr-3"}
              buttonClassName={"px-3 py-2"}
              optionsClassName={"text-[14px] font-medium w-[100%] no-scrollbar"}
              value={benchmarkAttribute.value}
              options={[
                { value: "lastSentiment", label: "Last Sentiment" },
                { value: "last3Sentiment", label: "Last 3 Sentiment" },
                { value: "firstSentiment", label: "First Sentiment" },
                { value: "first3Sentiment", label: "First 3 Sentiment" },
                { value: "allSentiment", label: "All Sentiment" },
              ]}
              onChange={setBenchmarkAttribute}
            />
            <Select<{ value: string; label: string }>
              className={
                "mr-4 h-fit min-w-fit !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-300"
              }
              prefix={"Benchmark with:"}
              inputClassName={"text-[14px] font-semibold mr-3"}
              buttonClassName={"px-3 py-2"}
              optionsClassName={"text-[14px] font-medium w-[100%] no-scrollbar"}
              value={fundSelected.value}
              options={restrictedFunds}
              onChange={setFundSelected}
            />
          </div>
          {maximised && isLaptop && (
            <a
              href={"#sentiment"}
              onClick={() => setMaximised((prev) => !prev)}
              className={
                "group flex select-none items-center rounded-[4px] px-3 py-2 outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100"
              }
            >
              <Icon
                type={maximised ? "Minimise" : "Maximise"}
                className={"size-4.5 group-hover:text-neutral-800 lg:mr-2"}
              />
              <p
                className={
                  "hidden text-nowrap text-[14px] font-semibold text-neutral-900 group-hover:text-neutral-800 lg:flex"
                }
              >
                {maximised ? "Minimise" : "Maximise and compare"}
              </p>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
