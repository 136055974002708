import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { useParentSize } from "@visx/responsive";
import { scaleOrdinal } from "@visx/scale";

import { useBreakpoints } from "@/lib/hooks";
import { cn } from "@/lib/utils.ts";

import { Icon } from "@/app/components";
import { XYDashboard } from "@/app/components/charts/xychart.tsx";
import { Select } from "@/app/components/input";
import { colors } from "@/app/constants";
import { formatNumber } from "@/app/screens/opportunities/opportunity/components";

export const MetricsXYChart = ({
  data1,
  selectedMetric: [selectedMetric, setSelectedMetric],
  maximised: [maximised, setMaximised],
  metricsSelectList = [],
  legendScaleOptions = { domain: ["Manual-Entry"], range: [colors.primary.DEFAULT] },
  navigateToMetricsExtraction,
}: {
  data1: { x: string; y: number; source?: string; prefix?: string; suffix?: string }[];
  selectedMetric: [string, (value: string | ((prevState: string) => string)) => void];
  maximised: [boolean, (value: boolean | ((prevState: boolean) => boolean)) => void];
  metricsSelectList?: { value: string; label: string }[];
  legendScaleOptions?: { domain: string[]; range: string[] };
  navigateToMetricsExtraction: () => void;
  companyId: string;
}) => {
  const legendScale = scaleOrdinal(legendScaleOptions);
  const { parentRef, width, height } = useParentSize({
    debounceTime: 250,
  });
  return (
    <div ref={parentRef} className={cn("pb-7", maximised ? "h-[77dvh]" : "h-[520px]")}>
      <Header
        legendScale={legendScale}
        metricsSelectList={metricsSelectList}
        maximised={maximised}
        setMaximised={setMaximised}
        selectedMetric={selectedMetric}
        setSelectedMetric={setSelectedMetric}
        navigateToMetricsExtraction={navigateToMetricsExtraction}
      />
      <XYDashboard
        height={height}
        data1={{ value: data1, label: selectedMetric }}
        maximised={maximised}
        leftAxisProps={{
          numTicks: selectedMetric === "Breakeven" ? 1 : maximised ? 16 : 8,
          tickFormat: (value) => {
            if (selectedMetric === "Breakeven") {
              return value === 1 ? "Yes" : "No";
            }
            if (selectedMetric === "Gross margin") return `${value}%`;
            return formatNumber(value);
          },
          hideZero: selectedMetric === "Breakeven",
          // axisLineClassName: "stroke-transparent",
          tickLineProps: { className: "stroke-transparent" },
        }}
      />
    </div>
  );
};

const Header = ({
  legendScale,
  metricsSelectList,
  setSelectedMetric,
  selectedMetric,
  setMaximised,
  maximised,
  navigateToMetricsExtraction,
}) => {
  const { isLaptop } = useBreakpoints();
  return (
    <>
      <div className={cn("flex items-center justify-between px-4 lg:px-6", !maximised && "mt-1 lg:mt-5")}>
        <div className={"flex items-center"}>
          {isLaptop && (
            <Select<{ value: string; label: string }>
              prefixClassName={"text-[14px] font-semibold text-neutral-600"}
              prefix={"Show metric:"}
              className={
                " min-w-fit !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-300"
              }
              inputClassName={"text-[14px] font-semibold mr-3"}
              buttonClassName={"px-3 py-2"}
              optionsClassName={"text-[14px] font-medium w-[100%] no-scrollbar"}
              value={selectedMetric}
              options={metricsSelectList}
              onChange={({ value }) => setSelectedMetric(value)}
            />
          )}
        </div>
        <div className={"hidden lg:flex"}>
          <Legend scale={legendScale} glyphSize={8} labelFormat={String} />
        </div>
        <div className={"flex items-center justify-center space-x-4"}>
          {isLaptop && (
            <a
              href={"#performance"}
              onClick={() => setMaximised((prevState) => !prevState)}
              className={
                "group flex select-none items-center rounded-[4px] px-3 py-2 outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100"
              }
            >
              <Icon
                type={maximised ? "Minimise" : "Maximise"}
                className={"size-4.5 group-hover:text-neutral-800 lg:mr-2"}
              />
              <p className={"hidden text-[14px] font-semibold text-neutral-900 group-hover:text-neutral-800 lg:flex"}>
                {maximised ? "Minimise" : "Maximise"}
              </p>
            </a>
          )}
          {!maximised && isLaptop && (
            <div
              onClick={navigateToMetricsExtraction}
              className={
                "group flex select-none items-center rounded-[4px] px-3 py-2 outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100"
              }
            >
              <Icon type={"Add"} className={"mr-2 size-4.5 group-hover:text-neutral-800"} />
              <p className={"text-[14px] font-semibold text-neutral-900 group-hover:text-neutral-800"}>Add metrics</p>
            </div>
          )}
        </div>
      </div>
      {!isLaptop && (
        <p className={"flex justify-between px-4 pt-4"}>
          <Select<{ value: string; label: string }>
            className={
              " min-w-fit !rounded-[4px] outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100 active:bg-neutral-300"
            }
            inputClassName={"text-[14px] font-semibold mr-3"}
            buttonClassName={"px-3 py-2"}
            optionsClassName={"text-[14px] font-medium w-[100%] no-scrollbar"}
            value={selectedMetric}
            options={metricsSelectList}
            onChange={({ value }) => setSelectedMetric(value)}
          />
          <a
            href={"#performance"}
            onClick={() => setMaximised((prevState) => !prevState)}
            className={
              "group flex select-none items-center rounded-[4px] px-3 py-2 outline outline-neutral-200 hover:cursor-pointer hover:bg-neutral-100"
            }
          >
            <Icon
              type={maximised ? "Minimise" : "Maximise"}
              className={"size-4.5 group-hover:text-neutral-800 lg:mr-2"}
            />
            <p className={"hidden text-[14px] font-semibold text-neutral-900 group-hover:text-neutral-800 lg:flex"}>
              {maximised ? "Minimise" : "Maximise"}
            </p>
          </a>
        </p>
      )}
    </>
  );
};

const Legend = ({ scale, labelFormat, glyphSize }) => {
  return (
    <div className={cn("flex w-full items-center rounded-[4px] bg-neutral-100 p-2")}>
      <p className={"mr-2 text-[14px] font-semibold text-neutral-600"}>Source</p>
      <div className={"flex items-center justify-center"}>
        <LegendOrdinal scale={scale} labelFormat={labelFormat}>
          {(labels) =>
            labels.map((label) => (
              <LegendItem key={`legend-${label.text}`} className={"min-w-12 pr-1"}>
                <svg width={glyphSize} height={glyphSize}>
                  <circle fill={label.value} r={glyphSize / 2} cx={glyphSize / 2} cy={glyphSize / 2} />
                </svg>
                <LegendLabel align="left" margin="0 4px">
                  <p className={"text-[12px] font-medium first-letter:uppercase"}>
                    {label.text.toLowerCase().replace(/-/g, " ")}
                  </p>
                </LegendLabel>
              </LegendItem>
            ))
          }
        </LegendOrdinal>
      </div>
    </div>
  );
};
