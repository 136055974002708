import React from "react";

import { LinearGradient } from "@visx/gradient";
import { useParentSize } from "@visx/responsive";
import { AnimatedAxis, AnimatedBarSeries, AnimatedGrid, lightTheme, Tooltip, XYChart } from "@visx/xychart";
import { BarChart2Icon, ChevronDown, TypeIcon } from "lucide-react";

import { cn } from "@/lib/utils.ts";

import { colors } from "@/app/constants";

const IconMap = {
  numeric: () => <BarChart2Icon className="mr-2 size-4 lg:size-6" />,
  opinion_scale: () => <BarChart2Icon className="mr-2 size-4 lg:size-6" />,
  short_text: () => <TypeIcon className="mr-2 mt-0.5 size-4 lg:size-6" />,
  multiple_choice: () => <ChevronDown className="mr-2 size-4 lg:size-6" />,
  dropdown: () => <ChevronDown className="mr-2 size-4 lg:size-6" />,
};

const accesors = {
  xAccessor: (data) => data[0],
  yAccessor: (data) => data[1].value,
};

const BarChart = ({ data, orinetation: orientation = "horizontal" }) => {
  const { parentRef, width, height } = useParentSize({
    debounceTime: 250,
  });
  const isHorizontal = orientation === "horizontal";

  const xConfig = isHorizontal ? { type: "band", paddingInner: 0.3 } : { type: "linear", zero: true, round: true };
  const yConfig = !isHorizontal ? { type: "band", paddingInner: 0.3 } : { type: "linear", zero: true, round: true };

  const margin = isHorizontal
    ? { top: 20, right: 0, bottom: 20, left: 0 }
    : { left: 200, right: 10, top: 0, bottom: 20 };
  const barChartData = isHorizontal ? data : data.toReversed();

  return (
    <div ref={parentRef} className={"relative flex-1 basis-1/3"}>
      <XYChart xScale={xConfig} yScale={yConfig} theme={lightTheme} width={width} height={height} margin={margin}>
        <LinearGradient id="area-gradient" from={colors.secondary.DEFAULT} to={colors.primary.DEFAULT} />
        <AnimatedGrid
          key={`grid-xy`} // force animate on update
          rows={!isHorizontal}
          columns={isHorizontal}
          strokeDasharray={"3"}
          strokeWidth={"2"}
        />

        <AnimatedAxis
          key={`time-axis`}
          orientation={"bottom"}
          strokeWidth={1}
          strokeDasharray={"8"}
          hideTicks={true}
          tickLabelProps={{ className: cn("text-xss font-semibold") }}
        />
        {orientation !== "horizontal" && (
          <AnimatedAxis
            tickLineProps={{ width: 280 }}
            key={`right-axis`}
            orientation={"left"}
            strokeWidth={1}
            strokeDasharray={"8"}
            tickLabelProps={{ width: 280 }}
          />
        )}

        <AnimatedBarSeries
          dataKey="line"
          barPadding={10}
          strokeWidth={0}
          stroke="url(#area-gradient)"
          fill="url(#area-gradient)"
          fillOpacity={0.9}
          data={barChartData}
          radius={4}
          radiusTop={isHorizontal}
          radiusRight={orientation !== "horizontal"}
          xAccessor={isHorizontal ? accesors.xAccessor : accesors.yAccessor}
          yAccessor={isHorizontal ? accesors.yAccessor : accesors.xAccessor}
          // curve={curveMonotoneX}
        />

        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showSeriesGlyphs
          horizontalCrosshairStyle={{ strokeDasharray: "8" }}
          renderTooltip={({ tooltipData }) => {
            if (!accesors.yAccessor(tooltipData?.nearestDatum?.datum)) return;

            return (
              <>
                <p className={"text-ss font-semibold"}>{accesors.yAccessor(tooltipData?.nearestDatum?.datum)}</p>
                <p>{tooltipData?.nearestDatum?.datum[1].people}</p>
              </>
            );
          }}
        />
      </XYChart>
    </div>
  );
};

export const SentimentAnswer = ({ form, field }) => {
  const IconComp = IconMap[field.type];

  const fieldAnswers = form.responses.flatMap((response) =>
    response.answers
      .filter((answer) => answer.field_id === field.field_id)
      .map((answer) => ({ ...answer, person: response.person })),
  );

  if (fieldAnswers.length === 0) {
    return;
  }

  return (
    <div className="shadow-none">
      <div className="flex flex-1">
        <div className="flex flex-row">
          <div>{IconComp && IconComp()}</div>
          <div className="text-lg font-semibold leading-5 tracking-tight">{field.name}</div>
        </div>
      </div>
      <div className="mt-2 grid gap-8">
        {(field.type === "opinion_scale" || field.type === "rating") && (
          <div className="flex h-40 justify-center lg:h-[220px] ">
            <BarChart
              data={Object.entries(
                fieldAnswers.reduce(
                  (prev, curr) => {
                    const { value, person } = curr;
                    if (!prev[value]) prev[value] = { value: 1, people: person };
                    else
                      prev[value] = {
                        value: prev[value].value + 1,
                        people: prev[value].people + ", " + person,
                      };

                    return prev;
                  },
                  Object.fromEntries([...Array(11).keys()].map((item) => [item, 0])),
                ),
              )}
            />
          </div>
        )}
        {field.type === "short_text" && (
          <div className="text-md justify-start gap-2 text-left lg:text-lg">
            {form.responses.flatMap((response) =>
              response.answers
                .filter((answer) => answer.field_id === field.field_id)
                .map((answer, index) => (
                  <pre key={index} className="whitespace-pre-wrap" style={{ overflowWrap: "anywhere" }}>
                    <b>{response.person}</b>
                    {"\n"}
                    {answer.value.toString()}
                    {"\n\n"}
                  </pre>
                )),
            )}
          </div>
        )}
        {field.type === "multiple_choice" && (
          <div className="flex h-40 justify-center lg:h-[280px]">
            <BarChart
              orinetation={field.group_id === "return" ? "horizontal" : "vertical"}
              data={Object.entries(
                form.responses
                  .flatMap((response) => response.answers.filter((answer) => answer.field_id === field.field_id))
                  .reduce(
                    (prev, curr) => {
                      const { value, person } = curr;
                      if (!prev[value]) prev[value] = { value: 1, people: person };
                      else
                        prev[value] = {
                          value: prev[value].value + 1,
                          people: prev[value].people + ", " + person,
                        };

                      return prev;
                    },
                    Object.fromEntries(field.properties.choices.map((choice) => [choice.label, 0])),
                  ),
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};
