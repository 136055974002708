import * as Types from "../../gql/graphql";

import { DocumentNode } from "graphql";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetCompanyQueryVariables = Types.Exact<{
  id: Types.Scalars["UUID"]["input"];
}>;

export type GetCompanyQuery = {
  __typename?: "Query";
  lgCompany?: {
    __typename?: "LgCompany";
    about: string;
    address: string;
    amount?: string | null;
    amountUsdMillion?: number | null;
    badges?: any | null;
    calculationStatus: string;
    cardLastActivity?: any | null;
    cardName?: string | null;
    city: string;
    companyStatus: string;
    continent: string;
    country: string;
    createdUtc?: any | null;
    currency: string;
    customOrder?: number | null;
    dealroomSignalCompleteness?: number | null;
    dealroomSignalGrowthRate?: number | null;
    dealroomSignalRating?: number | null;
    dealroomSignalTeamStrength?: number | null;
    dealroomSignalTiming?: number | null;
    employees?: string | null;
    employeesChart?: any | null;
    employeesLatest?: number | null;
    fundingDate?: string | null;
    growthChart?: any | null;
    hasPromisingFounder?: boolean | null;
    hasStrongFounder?: boolean | null;
    hasSuperFounder?: boolean | null;
    id: any;
    image: string;
    industries?: any | null;
    isActivelyFundraising: boolean;
    isBasecampFunded?: boolean | null;
    isFoundingLocation?: boolean | null;
    isNpa?: boolean | null;
    isOpCompany: boolean;
    isTrCompany: boolean;
    lastCalculatedUtc?: any | null;
    lastFundingDate?: any | null;
    lastUpdatedUtc?: any | null;
    launchYear?: number | null;
    linkedinUrl?: string | null;
    monthsFromLastFunding?: number | null;
    name: string;
    nodeId: string;
    relativeRunway?: string | null;
    runwayLastUpdate?: any | null;
    runwayEndDate?: any | null;
    tagline: string;
    tags?: any | null;
    targetFund: string;
    techStackPredictleads?: any | null;
    technologies?: any | null;
    totalFunding?: any | null;
    totalFundingSource?: string | null;
    twitterUrl?: string | null;
    websiteTrafficChart?: any | null;
    websiteUrl?: string | null;
    latestExcitement?: number | null;
    latestExcitementDate?: any | null;
    companiesSources: {
      __typename?: "SrcCompaniesConnection";
      items: Array<{
        __typename?: "SrcCompany";
        sourceType: string;
        sourceId: string;
        lastUpdatedUtc: any;
        dealroomCompanyAsSource?: {
          __typename?: "DealroomCompany";
          id: any;
          name?: string | null;
          about?: string | null;
          tagline?: string | null;
          websiteUrl?: string | null;
          path?: string | null;
          crunchbaseUrl?: string | null;
          url?: string | null;
          lastUpdatedUtc?: any | null;
          dealroomNewsByCompanyId: {
            __typename?: "DealroomNewsConnection";
            news: Array<{
              __typename?: "DealroomNews";
              author?: any | null;
              pubDate?: any | null;
              title?: string | null;
              summary?: string | null;
              image?: string | null;
              link?: string | null;
            }>;
          };
        } | null;
        trelloCardAsSource?: {
          __typename?: "TrelloCard";
          id: string;
          name: string;
          url: string;
          shortLinkHash: string;
          listId: string;
          boardId: string;
          comments?: any | null;
          attachments?: any | null;
          memberIds?: any | null;
          dateLastActivity?: any | null;
          list?: { __typename?: "TrelloList"; id: string; name: string } | null;
        } | null;
        opsCompanyAsSource?: {
          __typename?: "OpsCompany";
          id: any;
          name: string;
          initialInvestmentRatio?: number | null;
          opsCompanyfundsByCompanyId: {
            __typename?: "OpsCompanyfundsConnection";
            nodes: Array<{
              __typename?: "OpsCompanyfund";
              currentEv?: number | null;
              currentNav?: number | null;
              currentOwned?: number | null;
              cashoutGroup: string;
              currentMultiple?: number | null;
              unrealisedValue?: number | null;
              fund?: { __typename?: "OpsFund"; name: string } | null;
            }>;
          };
        } | null;
        mnEntryAsSource?: {
          __typename?: "MnEntry";
          id: number;
          data: any;
          lastUpdatedUtc: any;
          member?: {
            __typename?: "LgMember";
            id: any;
            initials: string;
            firstName: string;
            lastName: string;
            trelloMemberId?: string | null;
          } | null;
        } | null;
      }>;
    };
    signals: {
      __typename?: "SignalsConnection";
      items: Array<{
        __typename?: "Signal";
        id: any;
        createdUtc: any;
        output?: any | null;
        asignees: {
          __typename?: "SignalLgMembersByNzrOpportunitySignalIdAndAssigneeManyToManyConnection";
          nodes: Array<{
            __typename?: "LgMember";
            id: any;
            firstName: string;
            lastName: string;
            initials: string;
            avatar?: string | null;
            email: string;
          }>;
        };
        nzrOpportunities: {
          __typename?: "NzrOpportunitiesConnection";
          nodes: Array<{
            __typename?: "NzrOpportunity";
            status: string;
            lgMemberByAssignee?: {
              __typename?: "LgMember";
              id: any;
              firstName: string;
              email: string;
              lastName: string;
            } | null;
          }>;
        };
        rule?: { __typename?: "Rule"; name: string; runner: string } | null;
      }>;
    };
    fundingRounds: {
      __typename?: "LgFundingRoundsConnection";
      items: Array<{
        __typename?: "LgFundingRound";
        id: any;
        amount?: any | null;
        companyId: any;
        currency?: string | null;
        isVerified?: boolean | null;
        amountEurMillion?: any | null;
        amountSource?: any | null;
        amountUsdMillion?: any | null;
        year?: number | null;
        month?: number | null;
        round?: string | null;
        valuation?: any | null;
        investors: {
          __typename?: "LgFundingRoundInvestorsConnection";
          items: Array<{
            __typename?: "LgFundingRoundInvestor";
            lead?: boolean | null;
            investor?: {
              __typename?: "LgInvestor";
              id: any;
              name?: string | null;
              isBasecamp: boolean;
              about?: string | null;
              image?: string | null;
              fundLead?: {
                __typename?: "LgMember";
                id: any;
                firstName: string;
                lastName: string;
                avatar?: string | null;
              } | null;
            } | null;
          }>;
        };
      }>;
    };
    sentiment: {
      __typename?: "NzrSentimentFormsConnection";
      nodes: Array<{
        __typename?: "NzrSentimentForm";
        id: string;
        analytics?: any | null;
        category?: string | null;
        companyId?: any | null;
        companyName?: string | null;
        createdAt?: any | null;
        createdById?: any | null;
        expiresAt?: any | null;
        fields?: any | null;
        formStatus?: string | null;
        responses?: any | null;
        sentimentSummary?: string | null;
        participants: {
          __typename?: "NzrSentimentFormAssigneesConnection";
          nodes: Array<{
            __typename?: "NzrSentimentFormAssignee";
            id: any;
            state?: any | null;
            completed: boolean;
            member?: {
              __typename?: "LgMember";
              id: any;
              firstName: string;
              lastName: string;
              avatar?: string | null;
            } | null;
          }>;
        };
      }>;
    };
    team: {
      __typename?: "LgCompanyTeamsConnection";
      items: Array<{
        __typename?: "LgCompanyTeam";
        id: number;
        titles?: any | null;
        isFounder?: boolean | null;
        yearEnd?: number | null;
        yearStart?: number | null;
        companyTeams: {
          __typename?: "SrcCompanyTeamsConnection";
          nodes: Array<{ __typename?: "SrcCompanyTeam"; sourceType: string; lastUpdatedUtc: any }>;
        };
        member?: {
          __typename?: "LgFounder";
          id: any;
          name?: string | null;
          image?: string | null;
          linkedinUrl?: string | null;
          lastUpdatedUtc?: any | null;
          forms: {
            __typename?: "NzrSentimentFormsConnection";
            nodes: Array<{
              __typename?: "NzrSentimentForm";
              id: string;
              analytics?: any | null;
              category?: string | null;
              companyId?: any | null;
              companyName?: string | null;
              createdAt?: any | null;
              createdById?: any | null;
              expiresAt?: any | null;
              fields?: any | null;
              formStatus?: string | null;
              responses?: any | null;
              sentimentSummary?: string | null;
            }>;
          };
        } | null;
      }>;
    };
    investors: {
      __typename?: "LgCompanyLgInvestorsByLgCompanyInvestorCompanyIdAndInvestorIdManyToManyConnection";
      items: Array<{
        __typename?: "LgInvestor";
        id: any;
        name?: string | null;
        isBasecamp: boolean;
        about?: string | null;
        image?: string | null;
        fundLead?: {
          __typename?: "LgMember";
          id: any;
          firstName: string;
          lastName: string;
          avatar?: string | null;
        } | null;
      }>;
    };
    metrics: {
      __typename?: "LgCompanyMetricsConnection";
      nodes: Array<{ __typename?: "LgCompanyMetric"; asAtDate?: any | null; data?: any | null }>;
    };
    lastMetric?: { __typename?: "LgCompanyMetricsLatest"; data?: any | null; asAtDate?: any | null } | null;
    pcgOverview: {
      __typename?: "SrcCompaniesConnection";
      items: Array<{
        __typename?: "SrcCompany";
        sourceType: string;
        lastUpdatedUtc: any;
        opsCompanyAsSource?: {
          __typename?: "OpsCompany";
          id: any;
          initialInvestment?: number | null;
          initialInvestmentRatio?: number | null;
          investmentType?: string | null;
          isStale?: boolean | null;
          name: string;
          hqCity: string;
          companyFunds: {
            __typename?: "OpsCompanyfundsConnection";
            nodes: Array<{
              __typename?: "OpsCompanyfund";
              announced: boolean;
              braggy: string;
              cashoutDate?: any | null;
              cashoutGroup: string;
              coInvestors: string;
              cocMultiple?: number | null;
              companyId: any;
              id: any;
              currentEv?: number | null;
              currentMultiple?: number | null;
              currentNav?: number | null;
              currentNavFromFund?: number | null;
              currentOwned?: number | null;
              fundId?: any | null;
              goingInOwnership?: number | null;
              investmentType: string;
              latestExcitementScore?: number | null;
              initialInvestment?: number | null;
              totalInvestedCapital?: number | null;
              evAsFundReturner?: number | null;
              mtpEv?: number | null;
              investedCapitalFromFund?: number | null;
              unrealisedValue?: number | null;
              mostRecentRound: string;
              grossMargins?: number | null;
              lastRound?: number | null;
              revenue?: number | null;
              company?: {
                __typename?: "OpsCompany";
                name: string;
                id: any;
                totalNav?: number | null;
                initialInvestment?: number | null;
                totalInvestedCapital?: number | null;
                initialInvestmentRatio?: number | null;
                isFocus?: boolean | null;
                srcCompany?: {
                  __typename?: "SrcCompany";
                  company?: {
                    __typename?: "LgCompany";
                    image: string;
                    name: string;
                    tagline: string;
                    id: any;
                    latestExcitement?: number | null;
                    latestExcitementDate?: any | null;
                  } | null;
                } | null;
              } | null;
              fund?: { __typename?: "OpsFund"; name: string; parentFund: string } | null;
            }>;
          };
        } | null;
      }>;
    };
    mtpCompanyfundsByMtpCompanyId: {
      __typename?: "MtpCompanyfundsConnection";
      nodes: Array<{
        __typename?: "MtpCompanyfund";
        id: number;
        fund?: string | null;
        capitalConcentration?: string | null;
        runway?: string | null;
        revenue?: string | null;
        yoyGrowth?: string | null;
        margin?: string | null;
        fte?: string | null;
        evReturnFund?: string | null;
      }>;
    };
    userTags: {
      __typename?: "NzrUserCompanyTagsConnection";
      nodes: Array<{ __typename?: "NzrUserCompanyTag"; tags: any; userId: any }>;
    };
    companyFlags: {
      __typename?: "LgCompanyFlagsConnection";
      items: Array<{
        __typename?: "LgCompanyFlag";
        createdAt: any;
        updatedAt?: any | null;
        createdBy: any;
        companyId: any;
        flag: string;
      }>;
    };
    sentimentAnalytics?: {
      __typename?: "LgCompanySentimentAnalytic";
      lastSentiment: any;
      last3Sentiment: any;
      first3Sentiment: any;
      firstSentiment: any;
      allSentiment: any;
    } | null;
    creator?: { __typename?: "LgMember"; id: any; firstName: string; lastName: string; avatar?: string | null } | null;
    sentimentForms: {
      __typename?: "NzrSentimentFormsConnection";
      nodes: Array<{
        __typename?: "NzrSentimentForm";
        id: string;
        type?: string | null;
        companyName?: string | null;
        analytics?: any | null;
        createdAt?: any | null;
        status?: string | null;
        formStatus?: string | null;
        fields?: any | null;
        responses?: any | null;
      }>;
    };
  } | null;
};

export type GetCompaniesQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  filter?: Types.InputMaybe<Types.LgCompanyFilter>;
  orderBy?: Types.InputMaybe<Array<Types.LgCompaniesOrderBy> | Types.LgCompaniesOrderBy>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  after?: Types.InputMaybe<Types.Scalars["Cursor"]["input"]>;
}>;

export type GetCompaniesQuery = {
  __typename?: "Query";
  lgCompanies?: {
    __typename?: "LgCompaniesConnection";
    totalCount: number;
    edges: Array<{
      __typename?: "LgCompaniesEdge";
      node: {
        __typename?: "LgCompany";
        id: any;
        name: string;
        image: string;
        city: string;
        country: string;
        industries?: any | null;
        tagline: string;
        round?: string | null;
        badges?: any | null;
        userTags: {
          __typename?: "NzrUserCompanyTagsConnection";
          nodes: Array<{ __typename?: "NzrUserCompanyTag"; tags: any; userId: any }>;
        };
        companiesSources: {
          __typename?: "SrcCompaniesConnection";
          items: Array<{
            __typename?: "SrcCompany";
            sourceType: string;
            sourceId: string;
            lastUpdatedUtc: any;
            trelloCardAsSource?: {
              __typename?: "TrelloCard";
              id: string;
              name: string;
              url: string;
              shortLinkHash: string;
              listId: string;
              boardId: string;
              comments?: any | null;
              attachments?: any | null;
              memberIds?: any | null;
              dateLastActivity?: any | null;
            } | null;
          }>;
        };
        sentiment: {
          __typename?: "NzrSentimentFormsConnection";
          nodes: Array<{
            __typename?: "NzrSentimentForm";
            id: string;
            analytics?: any | null;
            category?: string | null;
            companyId?: any | null;
            companyName?: string | null;
            createdAt?: any | null;
            createdById?: any | null;
            expiresAt?: any | null;
            fields?: any | null;
            formStatus?: string | null;
            responses?: any | null;
            sentimentSummary?: string | null;
          }>;
        };
      };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};

export type SearchCompaniesGlobalQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  dealroomFilter?: Types.InputMaybe<Types.DealroomCompanyFilter>;
  lgFilter?: Types.InputMaybe<Types.LgCompanyFilter>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type SearchCompaniesGlobalQuery = {
  __typename?: "Query";
  dealroomCompanies?: {
    __typename?: "DealroomCompaniesConnection";
    nodes: Array<{
      __typename?: "DealroomCompany";
      id: any;
      image?: string | null;
      path?: string | null;
      industries?: any | null;
      name?: string | null;
      about?: string | null;
      tagline?: string | null;
      websiteUrl?: string | null;
      hqLocations?: any | null;
      team?: any | null;
    }>;
  } | null;
  lgCompanies?: {
    __typename?: "LgCompaniesConnection";
    nodes: Array<{
      __typename?: "LgCompany";
      about: string;
      address: string;
      amount?: string | null;
      amountUsdMillion?: number | null;
      badges?: any | null;
      calculationStatus: string;
      cardLastActivity?: any | null;
      cardName?: string | null;
      city: string;
      companyStatus: string;
      continent: string;
      country: string;
      createdUtc?: any | null;
      currency: string;
      customOrder?: number | null;
      dealroomSignalCompleteness?: number | null;
      dealroomSignalGrowthRate?: number | null;
      dealroomSignalRating?: number | null;
      dealroomSignalTeamStrength?: number | null;
      dealroomSignalTiming?: number | null;
      employees?: string | null;
      employeesChart?: any | null;
      employeesLatest?: number | null;
      fundingDate?: string | null;
      growthChart?: any | null;
      hasPromisingFounder?: boolean | null;
      hasStrongFounder?: boolean | null;
      hasSuperFounder?: boolean | null;
      id: any;
      image: string;
      industries?: any | null;
      isActivelyFundraising: boolean;
      isBasecampFunded?: boolean | null;
      isFoundingLocation?: boolean | null;
      isNpa?: boolean | null;
      isOpCompany: boolean;
      isTrCompany: boolean;
      lastCalculatedUtc?: any | null;
      lastFundingDate?: any | null;
      lastUpdatedUtc?: any | null;
      launchYear?: number | null;
      linkedinUrl?: string | null;
      monthsFromLastFunding?: number | null;
      name: string;
      nodeId: string;
      relativeRunway?: string | null;
      runwayLastUpdate?: any | null;
      runwayEndDate?: any | null;
      tagline: string;
      tags?: any | null;
      targetFund: string;
      techStackPredictleads?: any | null;
      technologies?: any | null;
      totalFunding?: any | null;
      totalFundingSource?: string | null;
      twitterUrl?: string | null;
      websiteTrafficChart?: any | null;
      websiteUrl?: string | null;
      latestExcitement?: number | null;
      latestExcitementDate?: any | null;
      sentimentForms: {
        __typename?: "NzrSentimentFormsConnection";
        nodes: Array<{
          __typename?: "NzrSentimentForm";
          id: string;
          type?: string | null;
          companyName?: string | null;
          analytics?: any | null;
          createdAt?: any | null;
          status?: string | null;
          formStatus?: string | null;
          fields?: any | null;
          responses?: any | null;
        }>;
      };
    }>;
  } | null;
};

export type SearchCompaniesLocalGlobeQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  filter?: Types.InputMaybe<Types.LgCompanyFilter>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type SearchCompaniesLocalGlobeQuery = {
  __typename?: "Query";
  results?: {
    __typename?: "LgCompaniesConnection";
    nodes: Array<{
      __typename?: "LgCompany";
      about: string;
      address: string;
      amount?: string | null;
      amountUsdMillion?: number | null;
      badges?: any | null;
      calculationStatus: string;
      cardLastActivity?: any | null;
      cardName?: string | null;
      city: string;
      companyStatus: string;
      continent: string;
      country: string;
      createdUtc?: any | null;
      currency: string;
      customOrder?: number | null;
      dealroomSignalCompleteness?: number | null;
      dealroomSignalGrowthRate?: number | null;
      dealroomSignalRating?: number | null;
      dealroomSignalTeamStrength?: number | null;
      dealroomSignalTiming?: number | null;
      employees?: string | null;
      employeesChart?: any | null;
      employeesLatest?: number | null;
      fundingDate?: string | null;
      growthChart?: any | null;
      hasPromisingFounder?: boolean | null;
      hasStrongFounder?: boolean | null;
      hasSuperFounder?: boolean | null;
      id: any;
      image: string;
      industries?: any | null;
      isActivelyFundraising: boolean;
      isBasecampFunded?: boolean | null;
      isFoundingLocation?: boolean | null;
      isNpa?: boolean | null;
      isOpCompany: boolean;
      isTrCompany: boolean;
      lastCalculatedUtc?: any | null;
      lastFundingDate?: any | null;
      lastUpdatedUtc?: any | null;
      launchYear?: number | null;
      linkedinUrl?: string | null;
      monthsFromLastFunding?: number | null;
      name: string;
      nodeId: string;
      relativeRunway?: string | null;
      runwayLastUpdate?: any | null;
      runwayEndDate?: any | null;
      tagline: string;
      tags?: any | null;
      targetFund: string;
      techStackPredictleads?: any | null;
      technologies?: any | null;
      totalFunding?: any | null;
      totalFundingSource?: string | null;
      twitterUrl?: string | null;
      websiteTrafficChart?: any | null;
      websiteUrl?: string | null;
      latestExcitement?: number | null;
      latestExcitementDate?: any | null;
      sentimentForms: {
        __typename?: "NzrSentimentFormsConnection";
        nodes: Array<{
          __typename?: "NzrSentimentForm";
          id: string;
          type?: string | null;
          companyName?: string | null;
          analytics?: any | null;
          createdAt?: any | null;
          status?: string | null;
          formStatus?: string | null;
          fields?: any | null;
          responses?: any | null;
        }>;
      };
    }>;
  } | null;
};

export type SearchCompaniesDealroomQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  filter?: Types.InputMaybe<Types.DealroomCompanyFilter>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type SearchCompaniesDealroomQuery = {
  __typename?: "Query";
  results?: {
    __typename?: "DealroomCompaniesConnection";
    nodes: Array<{
      __typename?: "DealroomCompany";
      id: any;
      image?: string | null;
      path?: string | null;
      industries?: any | null;
      name?: string | null;
      about?: string | null;
      tagline?: string | null;
      websiteUrl?: string | null;
      hqLocations?: any | null;
      team?: any | null;
    }>;
  } | null;
};

export type MeetThePortfolioQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  filter?: Types.InputMaybe<Types.OpsFundFilter>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type MeetThePortfolioQuery = {
  __typename?: "Query";
  opsFunds?: {
    __typename?: "OpsFundsConnection";
    nodes: Array<{
      __typename?: "OpsFund";
      name: string;
      id: any;
      mtp?: any | null;
      opsCompanyfundsByFundId: {
        __typename?: "OpsCompanyfundsConnection";
        nodes: Array<{
          __typename?: "OpsCompanyfund";
          navFundSize?: number | null;
          evReturnFund?: number | null;
          company?: {
            __typename?: "OpsCompany";
            name: string;
            initialInvestmentRatio?: number | null;
            totalInvestedCapital?: number | null;
            totalNav?: number | null;
            srcCompany?: {
              __typename?: "SrcCompany";
              company?: {
                __typename?: "LgCompany";
                id: any;
                badges?: any | null;
                round?: string | null;
                industries?: any | null;
                city: string;
                country: string;
                address: string;
                employeesLatest?: number | null;
                name: string;
                last3Sentiment?: any | null;
                image: string;
                tags?: any | null;
                rating?: number | null;
                latestMetrics?: {
                  __typename?: "LgCompanyMetricsLatest";
                  data?: any | null;
                  asAtDate?: any | null;
                } | null;
              } | null;
            } | null;
          } | null;
        }>;
      };
      mtpCompanyfundsByMtpFundId: {
        __typename?: "MtpCompanyfundsConnection";
        nodes: Array<{
          __typename?: "MtpCompanyfund";
          breakeven?: boolean | null;
          capitalConcentration?: string | null;
          companyName?: string | null;
          companyType?: string | null;
          navFundSize?: number | null;
          evReturnFund?: string | null;
          revenue?: string | null;
          yoyGrowth?: string | null;
          margin?: string | null;
          followOnCapital?: string | null;
          freeCashFlow?: boolean | null;
          fte?: string | null;
          runway?: string | null;
          fund?: string | null;
          id: number;
          mtpCompany?: {
            __typename?: "LgCompany";
            id: any;
            badges?: any | null;
            round?: string | null;
            industries?: any | null;
            city: string;
            country: string;
            address: string;
            name: string;
            image: string;
            tags?: any | null;
            rating?: number | null;
            latestMetrics?: { __typename?: "LgCompanyMetricsLatest"; data?: any | null; asAtDate?: any | null } | null;
          } | null;
        }>;
      };
    }>;
  } | null;
};

export type OpsCompaniesQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
  filter?: Types.InputMaybe<Types.OpsCompanyFilter>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]["input"]>;
}>;

export type OpsCompaniesQuery = {
  __typename?: "Query";
  opsCompanies?: {
    __typename?: "OpsCompaniesConnection";
    edges: Array<{
      __typename?: "OpsCompaniesEdge";
      node: {
        __typename?: "OpsCompany";
        srcCompany?: {
          __typename?: "SrcCompany";
          opsCompanyAsSource?: {
            __typename?: "OpsCompany";
            opsCompanyfundsByCompanyId: {
              __typename?: "OpsCompanyfundsConnection";
              nodes: Array<{
                __typename?: "OpsCompanyfund";
                announced: boolean;
                braggy: string;
                cashoutDate?: any | null;
                cashoutGroup: string;
                coInvestors: string;
                cocMultiple?: number | null;
                companyId: any;
                id: any;
                currentEv?: number | null;
                currentMultiple?: number | null;
                currentNav?: number | null;
                currentNavFromFund?: number | null;
                currentOwned?: number | null;
                fundId?: any | null;
                goingInOwnership?: number | null;
                investmentType: string;
                latestExcitementScore?: number | null;
                initialInvestment?: number | null;
                totalInvestedCapital?: number | null;
                evAsFundReturner?: number | null;
                mtpEv?: number | null;
                investedCapitalFromFund?: number | null;
                unrealisedValue?: number | null;
                mostRecentRound: string;
                grossMargins?: number | null;
                lastRound?: number | null;
                revenue?: number | null;
                company?: {
                  __typename?: "OpsCompany";
                  name: string;
                  id: any;
                  totalNav?: number | null;
                  initialInvestment?: number | null;
                  totalInvestedCapital?: number | null;
                  initialInvestmentRatio?: number | null;
                  isFocus?: boolean | null;
                  srcCompany?: {
                    __typename?: "SrcCompany";
                    company?: {
                      __typename?: "LgCompany";
                      image: string;
                      name: string;
                      tagline: string;
                      id: any;
                      latestExcitement?: number | null;
                      latestExcitementDate?: any | null;
                    } | null;
                  } | null;
                } | null;
                fund?: { __typename?: "OpsFund"; name: string; parentFund: string } | null;
              }>;
            };
          } | null;
        } | null;
      };
    }>;
    nodes: Array<{
      __typename?: "OpsCompany";
      id: any;
      name: string;
      totalInvestedCapital?: number | null;
      totalNav?: number | null;
      investmentType?: string | null;
      initialInvestmentRatio?: number | null;
      initialInvestment?: number | null;
    }>;
  } | null;
};

export type OpsFundsQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.OpsCompanyfundFilter>;
  orderBy?: Types.InputMaybe<Array<Types.OpsCompanyfundsOrderBy> | Types.OpsCompanyfundsOrderBy>;
}>;

export type OpsFundsQuery = {
  __typename?: "Query";
  opsCompanyfunds?: {
    __typename?: "OpsCompanyfundsConnection";
    edges: Array<{
      __typename?: "OpsCompanyfundsEdge";
      node: {
        __typename?: "OpsCompanyfund";
        announced: boolean;
        braggy: string;
        cashoutDate?: any | null;
        cashoutGroup: string;
        coInvestors: string;
        cocMultiple?: number | null;
        companyId: any;
        id: any;
        currentEv?: number | null;
        currentMultiple?: number | null;
        currentNav?: number | null;
        currentNavFromFund?: number | null;
        currentOwned?: number | null;
        fundId?: any | null;
        goingInOwnership?: number | null;
        investmentType: string;
        latestExcitementScore?: number | null;
        initialInvestment?: number | null;
        totalInvestedCapital?: number | null;
        evAsFundReturner?: number | null;
        mtpEv?: number | null;
        investedCapitalFromFund?: number | null;
        unrealisedValue?: number | null;
        mostRecentRound: string;
        grossMargins?: number | null;
        lastRound?: number | null;
        revenue?: number | null;
        company?: {
          __typename?: "OpsCompany";
          name: string;
          id: any;
          totalNav?: number | null;
          initialInvestment?: number | null;
          totalInvestedCapital?: number | null;
          initialInvestmentRatio?: number | null;
          isFocus?: boolean | null;
          srcCompany?: {
            __typename?: "SrcCompany";
            company?: {
              __typename?: "LgCompany";
              image: string;
              name: string;
              tagline: string;
              id: any;
              latestExcitement?: number | null;
              latestExcitementDate?: any | null;
            } | null;
          } | null;
        } | null;
        fund?: { __typename?: "OpsFund"; name: string; parentFund: string } | null;
      };
    }>;
  } | null;
};

export type OpportunityActionMutationVariables = Types.Exact<{
  action: Types.Action;
  actionPayloadInput?: Types.InputMaybe<Types.ActionPayloadInput>;
  entity: Types.Entity;
}>;

export type OpportunityActionMutation = { __typename?: "Mutation"; opportunityAction?: any | null };

export type AddOpportunityMutationVariables = Types.Exact<{
  input: Types.ManualOpportunityInput;
}>;

export type AddOpportunityMutation = { __typename?: "Mutation"; opportunityNew?: any | null };

export type UpdateOpportunityMutationVariables = Types.Exact<{
  opportunityId: Types.Scalars["String"]["input"];
  input: Types.UpdateCompanyInput;
}>;

export type UpdateOpportunityMutation = { __typename?: "Mutation"; opportunityUpdate?: any | null };

export type FlagCompanyMutationVariables = Types.Exact<{
  input: Types.FlagCompanyInput;
}>;

export type FlagCompanyMutation = { __typename?: "Mutation"; companyFlags?: any | null };

export type EmailOpportunityMutationVariables = Types.Exact<{
  input: Types.EmailOpportunityInput;
}>;

export type EmailOpportunityMutation = { __typename?: "Mutation"; opportunityEmail?: any | null };

export type UnAssignUserActionMutationVariables = Types.Exact<{
  input: Types.UnAssignPayloadInput;
}>;

export type UnAssignUserActionMutation = { __typename?: "Mutation"; unAssignUser?: any | null };

export type TopInvestorsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type TopInvestorsQuery = {
  __typename?: "Query";
  lgInvestors?: {
    __typename?: "LgInvestorsConnection";
    totalCount: number;
    nodes: Array<{ __typename?: "LgInvestor"; id: any; name?: string | null; lgRanking?: any | null }>;
  } | null;
};

export type SubmitFeedbackMutationVariables = Types.Exact<{
  input: Types.UserFeedbackInput;
}>;

export type SubmitFeedbackMutation = { __typename?: "Mutation"; submitFeedback?: any | null };

export type TagUserCompanyMutationVariables = Types.Exact<{
  input: Types.TagCompanyInput;
}>;

export type TagUserCompanyMutation = { __typename?: "Mutation"; userCompanyTags?: any | null };

export type UploadCompanyAttachmentMutationVariables = Types.Exact<{
  companyId: Types.Scalars["ID"]["input"];
  name: Types.Scalars["String"]["input"];
  path: Types.Scalars["String"]["input"];
}>;

export type UploadCompanyAttachmentMutation = { __typename?: "Mutation"; companyAttachmentUpload?: any | null };

export const GetCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompany" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "FragmentSpread", name: { kind: "Name", value: "LgCompanyFragment" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "companiesSources" },
                  name: { kind: "Name", value: "srcCompaniesByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "CompanySourceFragment" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "signals" },
                  name: { kind: "Name", value: "signalsByCompanyId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_UTC_DESC" }] },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "1" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "SignalCompanyFragment" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "asignees" },
                              name: { kind: "Name", value: "lgMembersByNzrOpportunitySignalIdAndAssignee" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                        { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                        { kind: "Field", name: { kind: "Name", value: "initials" } },
                                        { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                        { kind: "Field", name: { kind: "Name", value: "email" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nzrOpportunities" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "lgMemberByAssignee" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                              { kind: "Field", name: { kind: "Name", value: "email" } },
                                              { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "status" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "fundingRounds" },
                  name: { kind: "Name", value: "lgFundingRoundsByCompanyId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: {
                        kind: "ListValue",
                        values: [
                          { kind: "EnumValue", value: "YEAR_DESC" },
                          { kind: "EnumValue", value: "MONTH_DESC" },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "LgFundingRoundFragment" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "investors" },
                              name: { kind: "Name", value: "lgFundingRoundInvestorsByFundingRoundId" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "items" },
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: { kind: "Name", value: "LgFundingRoundInvestorFragment" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "sentiment" },
                  name: { kind: "Name", value: "nzrSentimentFormsByCompanyId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "founderExists" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                      },
                    },
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }] },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "NzrSentimentFormFrag" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "participants" },
                              name: { kind: "Name", value: "nzrSentimentFormAssignees" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "state" } },
                                        { kind: "Field", name: { kind: "Name", value: "completed" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "member" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                              { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                              { kind: "Field", name: { kind: "Name", value: "avatar" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "team" },
                  name: { kind: "Name", value: "lgCompanyTeamsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgTeamFragment" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "investors" },
                  name: { kind: "Name", value: "lgInvestorsByLgCompanyInvestorCompanyIdAndInvestorId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgInvestorFragment" } }],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "metrics" },
                  name: { kind: "Name", value: "lgCompanyMetricsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "asAtDate" } },
                            { kind: "Field", name: { kind: "Name", value: "data" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "lastMetric" },
                  name: { kind: "Name", value: "lgCompanyMetricsLatestByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "data" } },
                      { kind: "Field", name: { kind: "Name", value: "asAtDate" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "pcgOverview" },
                  name: { kind: "Name", value: "srcCompaniesByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "sourceType" } },
                            { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "opsCompanyAsSource" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                                  { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                                  { kind: "Field", name: { kind: "Name", value: "investmentType" } },
                                  { kind: "Field", name: { kind: "Name", value: "isStale" } },
                                  { kind: "Field", name: { kind: "Name", value: "name" } },
                                  { kind: "Field", name: { kind: "Name", value: "hqCity" } },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "companyFunds" },
                                    name: { kind: "Name", value: "opsCompanyfundsByCompanyId" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "nodes" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "FragmentSpread", name: { kind: "Name", value: "Fund" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "mtpCompanyfundsByMtpCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "fund" } },
                            { kind: "Field", name: { kind: "Name", value: "capitalConcentration" } },
                            { kind: "Field", name: { kind: "Name", value: "runway" } },
                            { kind: "Field", name: { kind: "Name", value: "revenue" } },
                            { kind: "Field", name: { kind: "Name", value: "yoyGrowth" } },
                            { kind: "Field", name: { kind: "Name", value: "margin" } },
                            { kind: "Field", name: { kind: "Name", value: "fte" } },
                            { kind: "Field", name: { kind: "Name", value: "runway" } },
                            { kind: "Field", name: { kind: "Name", value: "evReturnFund" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "userTags" },
                  name: { kind: "Name", value: "nzrUserCompanyTagsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "tags" } },
                            { kind: "Field", name: { kind: "Name", value: "userId" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "companyFlags" },
                  name: { kind: "Name", value: "lgCompanyFlagsByCompanyId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "deletedAt" },
                            value: {
                              kind: "ObjectValue",
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "isNull" },
                                  value: { kind: "BooleanValue", value: true },
                                },
                              ],
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "items" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                            { kind: "Field", name: { kind: "Name", value: "createdBy" } },
                            { kind: "Field", name: { kind: "Name", value: "companyId" } },
                            { kind: "Field", name: { kind: "Name", value: "flag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "sentimentAnalytics" },
                  name: { kind: "Name", value: "lgCompanySentimentAnalyticByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "lastSentiment" } },
                      { kind: "Field", name: { kind: "Name", value: "last3Sentiment" } },
                      { kind: "Field", name: { kind: "Name", value: "first3Sentiment" } },
                      { kind: "Field", name: { kind: "Name", value: "firstSentiment" } },
                      { kind: "Field", name: { kind: "Name", value: "allSentiment" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creator" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "avatar" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "badges" } },
          { kind: "Field", name: { kind: "Name", value: "calculationStatus" } },
          { kind: "Field", name: { kind: "Name", value: "cardLastActivity" } },
          { kind: "Field", name: { kind: "Name", value: "cardName" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "sentimentForms" },
            name: { kind: "Name", value: "nzrSentimentFormsByCompanyId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "companyName" } },
                      { kind: "Field", name: { kind: "Name", value: "analytics" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "formStatus" } },
                      { kind: "Field", name: { kind: "Name", value: "fields" } },
                      { kind: "Field", name: { kind: "Name", value: "responses" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "companyStatus" } },
          { kind: "Field", name: { kind: "Name", value: "continent" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "customOrder" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalCompleteness" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalGrowthRate" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalRating" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTeamStrength" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTiming" } },
          { kind: "Field", name: { kind: "Name", value: "employees" } },
          { kind: "Field", name: { kind: "Name", value: "employeesChart" } },
          { kind: "Field", name: { kind: "Name", value: "employeesLatest" } },
          { kind: "Field", name: { kind: "Name", value: "fundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "growthChart" } },
          { kind: "Field", name: { kind: "Name", value: "hasPromisingFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasStrongFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasSuperFounder" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          { kind: "Field", name: { kind: "Name", value: "industries" } },
          { kind: "Field", name: { kind: "Name", value: "isActivelyFundraising" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecampFunded" } },
          { kind: "Field", name: { kind: "Name", value: "isFoundingLocation" } },
          { kind: "Field", name: { kind: "Name", value: "isNpa" } },
          { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
          { kind: "Field", name: { kind: "Name", value: "isTrCompany" } },
          { kind: "Field", name: { kind: "Name", value: "lastCalculatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lastFundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "launchYear" } },
          { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
          { kind: "Field", name: { kind: "Name", value: "monthsFromLastFunding" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "nodeId" } },
          { kind: "Field", name: { kind: "Name", value: "relativeRunway" } },
          { kind: "Field", name: { kind: "Name", value: "runwayLastUpdate" } },
          { kind: "Field", name: { kind: "Name", value: "runwayEndDate" } },
          { kind: "Field", name: { kind: "Name", value: "tagline" } },
          { kind: "Field", name: { kind: "Name", value: "tags" } },
          { kind: "Field", name: { kind: "Name", value: "targetFund" } },
          { kind: "Field", name: { kind: "Name", value: "techStackPredictleads" } },
          { kind: "Field", name: { kind: "Name", value: "technologies" } },
          { kind: "Field", name: { kind: "Name", value: "totalFunding" } },
          { kind: "Field", name: { kind: "Name", value: "totalFundingSource" } },
          { kind: "Field", name: { kind: "Name", value: "twitterUrl" } },
          { kind: "Field", name: { kind: "Name", value: "websiteTrafficChart" } },
          { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CompanySourceFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "SrcCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "sourceType" } },
          { kind: "Field", name: { kind: "Name", value: "sourceId" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dealroomCompanyAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "about" } },
                { kind: "Field", name: { kind: "Name", value: "tagline" } },
                { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                { kind: "Field", name: { kind: "Name", value: "path" } },
                { kind: "Field", name: { kind: "Name", value: "crunchbaseUrl" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dealroomNewsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "news" },
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "author" } },
                            { kind: "Field", name: { kind: "Name", value: "pubDate" } },
                            { kind: "Field", name: { kind: "Name", value: "title" } },
                            { kind: "Field", name: { kind: "Name", value: "summary" } },
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "link" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "trelloCardAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "url" } },
                { kind: "Field", name: { kind: "Name", value: "shortLinkHash" } },
                { kind: "Field", name: { kind: "Name", value: "listId" } },
                { kind: "Field", name: { kind: "Name", value: "boardId" } },
                { kind: "Field", name: { kind: "Name", value: "comments" } },
                { kind: "Field", name: { kind: "Name", value: "attachments" } },
                { kind: "Field", name: { kind: "Name", value: "memberIds" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "list" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "dateLastActivity" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "opsCompanyAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "opsCompanyfundsByCompanyId" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "currentEv" } },
                            { kind: "Field", name: { kind: "Name", value: "currentNav" } },
                            { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
                            { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
                            { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fund" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [{ kind: "Field", name: { kind: "Name", value: "name" } }],
                              },
                            },
                            { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "mnEntryAsSource" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "data" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "member" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "initials" } },
                      { kind: "Field", name: { kind: "Name", value: "firstName" } },
                      { kind: "Field", name: { kind: "Name", value: "lastName" } },
                      { kind: "Field", name: { kind: "Name", value: "trelloMemberId" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "SignalCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Signal" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "rule" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "runner" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "output" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgFundingRoundFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgFundingRound" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "isVerified" } },
          { kind: "Field", name: { kind: "Name", value: "amountEurMillion" } },
          { kind: "Field", name: { kind: "Name", value: "amountSource" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "year" } },
          { kind: "Field", name: { kind: "Name", value: "month" } },
          { kind: "Field", name: { kind: "Name", value: "round" } },
          { kind: "Field", name: { kind: "Name", value: "valuation" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgFundingRoundInvestorFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgFundingRoundInvestor" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "lead" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "investor" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgInvestorFragment" } }],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgInvestorFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgInvestor" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecamp" } },
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "fundLead" },
            name: { kind: "Name", value: "lgMemberByRefFundLead" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "avatar" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrSentimentFormFrag" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrSentimentForm" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "analytics" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdById" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "fields" } },
          { kind: "Field", name: { kind: "Name", value: "formStatus" } },
          { kind: "Field", name: { kind: "Name", value: "responses" } },
          { kind: "Field", name: { kind: "Name", value: "sentimentSummary" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgTeamFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyTeam" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "titles" } },
          { kind: "Field", name: { kind: "Name", value: "isFounder" } },
          { kind: "Field", name: { kind: "Name", value: "yearEnd" } },
          { kind: "Field", name: { kind: "Name", value: "yearStart" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "companyTeams" },
            name: { kind: "Name", value: "srcCompanyTeamsByEntityId" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "sourceType" } },
                      { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "member" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "image" } },
                { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
                { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "forms" },
                  name: { kind: "Name", value: "nzrSentimentFormsByFounderId" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }] },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "FragmentSpread", name: { kind: "Name", value: "NzrSentimentFormFrag" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Fund" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfund" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "announced" } },
          { kind: "Field", name: { kind: "Name", value: "braggy" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutDate" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "coInvestors" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "company" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "srcCompany" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tagline" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "totalNav" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                { kind: "Field", name: { kind: "Name", value: "isFocus" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentEv" } },
          { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentNav" } },
          { kind: "Field", name: { kind: "Name", value: "currentNavFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fund" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "parentFund" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "fundId" } },
          { kind: "Field", name: { kind: "Name", value: "goingInOwnership" } },
          { kind: "Field", name: { kind: "Name", value: "investmentType" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementScore" } },
          { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
          { kind: "Field", name: { kind: "Name", value: "evAsFundReturner" } },
          { kind: "Field", name: { kind: "Name", value: "mtpEv" } },
          { kind: "Field", name: { kind: "Name", value: "investedCapitalFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
          { kind: "Field", name: { kind: "Name", value: "mostRecentRound" } },
          { kind: "Field", name: { kind: "Name", value: "grossMargins" } },
          { kind: "Field", name: { kind: "Name", value: "lastRound" } },
          { kind: "Field", name: { kind: "Name", value: "revenue" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables> &
    ({ variables: GetCompanyQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
}
export function useGetCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
}
export function useGetCompanySuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, options);
}
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanySuspenseQueryHookResult = ReturnType<typeof useGetCompanySuspenseQuery>;
export type GetCompanyQueryResult = Apollo.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "LgCompaniesOrderBy" } },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "after" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: { kind: "Variable", name: { kind: "Name", value: "after" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "city" } },
                            { kind: "Field", name: { kind: "Name", value: "country" } },
                            { kind: "Field", name: { kind: "Name", value: "industries" } },
                            { kind: "Field", name: { kind: "Name", value: "tagline" } },
                            { kind: "Field", name: { kind: "Name", value: "round" } },
                            { kind: "Field", name: { kind: "Name", value: "badges" } },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "userTags" },
                              name: { kind: "Name", value: "nzrUserCompanyTagsByCompanyId" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "tags" } },
                                        { kind: "Field", name: { kind: "Name", value: "userId" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "companiesSources" },
                              name: { kind: "Name", value: "srcCompaniesByCompanyId" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "items" },
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "sourceType" } },
                                        { kind: "Field", name: { kind: "Name", value: "sourceId" } },
                                        { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "trelloCardAsSource" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "id" } },
                                              { kind: "Field", name: { kind: "Name", value: "name" } },
                                              { kind: "Field", name: { kind: "Name", value: "url" } },
                                              { kind: "Field", name: { kind: "Name", value: "shortLinkHash" } },
                                              { kind: "Field", name: { kind: "Name", value: "listId" } },
                                              { kind: "Field", name: { kind: "Name", value: "boardId" } },
                                              { kind: "Field", name: { kind: "Name", value: "comments" } },
                                              { kind: "Field", name: { kind: "Name", value: "attachments" } },
                                              { kind: "Field", name: { kind: "Name", value: "memberIds" } },
                                              { kind: "Field", name: { kind: "Name", value: "dateLastActivity" } },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              alias: { kind: "Name", value: "sentiment" },
                              name: { kind: "Name", value: "nzrSentimentFormsByCompanyId" },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    kind: "ObjectValue",
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "founderExists" },
                                        value: { kind: "BooleanValue", value: false },
                                      },
                                    ],
                                  },
                                },
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "orderBy" },
                                  value: {
                                    kind: "ListValue",
                                    values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }],
                                  },
                                },
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "first" },
                                  value: { kind: "IntValue", value: "3" },
                                },
                              ],
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "FragmentSpread",
                                          name: { kind: "Name", value: "NzrSentimentFormFrag" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pageInfo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "hasNextPage" } },
                      { kind: "Field", name: { kind: "Name", value: "hasPreviousPage" } },
                      { kind: "Field", name: { kind: "Name", value: "startCursor" } },
                      { kind: "Field", name: { kind: "Name", value: "endCursor" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NzrSentimentFormFrag" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "NzrSentimentForm" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "analytics" } },
          { kind: "Field", name: { kind: "Name", value: "category" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          { kind: "Field", name: { kind: "Name", value: "companyName" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdById" } },
          { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
          { kind: "Field", name: { kind: "Name", value: "fields" } },
          { kind: "Field", name: { kind: "Name", value: "formStatus" } },
          { kind: "Field", name: { kind: "Name", value: "responses" } },
          { kind: "Field", name: { kind: "Name", value: "sentimentSummary" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
}
export function useGetCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
}
export function useGetCompaniesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(GetCompaniesDocument, options);
}
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesSuspenseQueryHookResult = ReturnType<typeof useGetCompaniesSuspenseQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<GetCompaniesQuery, GetCompaniesQueryVariables>;
export const SearchCompaniesGlobalDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchCompaniesGlobal" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "dealroomFilter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "DealroomCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lgFilter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "dealroomCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "dealroomFilter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "NAME_FUZZY_ASC" }] },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "image" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                      { kind: "Field", name: { kind: "Name", value: "industries" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "about" } },
                      { kind: "Field", name: { kind: "Name", value: "tagline" } },
                      { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "hqLocations" } },
                      { kind: "Field", name: { kind: "Name", value: "team" } },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lgCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "lgFilter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "NAME_FUZZY_ASC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgCompanyFragment" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "badges" } },
          { kind: "Field", name: { kind: "Name", value: "calculationStatus" } },
          { kind: "Field", name: { kind: "Name", value: "cardLastActivity" } },
          { kind: "Field", name: { kind: "Name", value: "cardName" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "sentimentForms" },
            name: { kind: "Name", value: "nzrSentimentFormsByCompanyId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "companyName" } },
                      { kind: "Field", name: { kind: "Name", value: "analytics" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "formStatus" } },
                      { kind: "Field", name: { kind: "Name", value: "fields" } },
                      { kind: "Field", name: { kind: "Name", value: "responses" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "companyStatus" } },
          { kind: "Field", name: { kind: "Name", value: "continent" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "customOrder" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalCompleteness" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalGrowthRate" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalRating" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTeamStrength" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTiming" } },
          { kind: "Field", name: { kind: "Name", value: "employees" } },
          { kind: "Field", name: { kind: "Name", value: "employeesChart" } },
          { kind: "Field", name: { kind: "Name", value: "employeesLatest" } },
          { kind: "Field", name: { kind: "Name", value: "fundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "growthChart" } },
          { kind: "Field", name: { kind: "Name", value: "hasPromisingFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasStrongFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasSuperFounder" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          { kind: "Field", name: { kind: "Name", value: "industries" } },
          { kind: "Field", name: { kind: "Name", value: "isActivelyFundraising" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecampFunded" } },
          { kind: "Field", name: { kind: "Name", value: "isFoundingLocation" } },
          { kind: "Field", name: { kind: "Name", value: "isNpa" } },
          { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
          { kind: "Field", name: { kind: "Name", value: "isTrCompany" } },
          { kind: "Field", name: { kind: "Name", value: "lastCalculatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lastFundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "launchYear" } },
          { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
          { kind: "Field", name: { kind: "Name", value: "monthsFromLastFunding" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "nodeId" } },
          { kind: "Field", name: { kind: "Name", value: "relativeRunway" } },
          { kind: "Field", name: { kind: "Name", value: "runwayLastUpdate" } },
          { kind: "Field", name: { kind: "Name", value: "runwayEndDate" } },
          { kind: "Field", name: { kind: "Name", value: "tagline" } },
          { kind: "Field", name: { kind: "Name", value: "tags" } },
          { kind: "Field", name: { kind: "Name", value: "targetFund" } },
          { kind: "Field", name: { kind: "Name", value: "techStackPredictleads" } },
          { kind: "Field", name: { kind: "Name", value: "technologies" } },
          { kind: "Field", name: { kind: "Name", value: "totalFunding" } },
          { kind: "Field", name: { kind: "Name", value: "totalFundingSource" } },
          { kind: "Field", name: { kind: "Name", value: "twitterUrl" } },
          { kind: "Field", name: { kind: "Name", value: "websiteTrafficChart" } },
          { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSearchCompaniesGlobalQuery__
 *
 * To run a query within a React component, call `useSearchCompaniesGlobalQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompaniesGlobalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompaniesGlobalQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      dealroomFilter: // value for 'dealroomFilter'
 *      lgFilter: // value for 'lgFilter'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchCompaniesGlobalQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchCompaniesGlobalQuery, SearchCompaniesGlobalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchCompaniesGlobalQuery, SearchCompaniesGlobalQueryVariables>(
    SearchCompaniesGlobalDocument,
    options,
  );
}
export function useSearchCompaniesGlobalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchCompaniesGlobalQuery, SearchCompaniesGlobalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchCompaniesGlobalQuery, SearchCompaniesGlobalQueryVariables>(
    SearchCompaniesGlobalDocument,
    options,
  );
}
export function useSearchCompaniesGlobalSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SearchCompaniesGlobalQuery, SearchCompaniesGlobalQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchCompaniesGlobalQuery, SearchCompaniesGlobalQueryVariables>(
    SearchCompaniesGlobalDocument,
    options,
  );
}
export type SearchCompaniesGlobalQueryHookResult = ReturnType<typeof useSearchCompaniesGlobalQuery>;
export type SearchCompaniesGlobalLazyQueryHookResult = ReturnType<typeof useSearchCompaniesGlobalLazyQuery>;
export type SearchCompaniesGlobalSuspenseQueryHookResult = ReturnType<typeof useSearchCompaniesGlobalSuspenseQuery>;
export type SearchCompaniesGlobalQueryResult = Apollo.QueryResult<
  SearchCompaniesGlobalQuery,
  SearchCompaniesGlobalQueryVariables
>;
export const SearchCompaniesLocalGlobeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchCompaniesLocalGlobe" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "LgCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "results" },
            name: { kind: "Name", value: "lgCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "NAME_FUZZY_ASC" }] },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "LgCompanyFragment" } }],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "LgCompanyFragment" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "LgCompany" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "about" } },
          { kind: "Field", name: { kind: "Name", value: "address" } },
          { kind: "Field", name: { kind: "Name", value: "amount" } },
          { kind: "Field", name: { kind: "Name", value: "amountUsdMillion" } },
          { kind: "Field", name: { kind: "Name", value: "badges" } },
          { kind: "Field", name: { kind: "Name", value: "calculationStatus" } },
          { kind: "Field", name: { kind: "Name", value: "cardLastActivity" } },
          { kind: "Field", name: { kind: "Name", value: "cardName" } },
          { kind: "Field", name: { kind: "Name", value: "city" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "sentimentForms" },
            name: { kind: "Name", value: "nzrSentimentFormsByCompanyId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      { kind: "Field", name: { kind: "Name", value: "companyName" } },
                      { kind: "Field", name: { kind: "Name", value: "analytics" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "status" } },
                      { kind: "Field", name: { kind: "Name", value: "formStatus" } },
                      { kind: "Field", name: { kind: "Name", value: "fields" } },
                      { kind: "Field", name: { kind: "Name", value: "responses" } },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "companyStatus" } },
          { kind: "Field", name: { kind: "Name", value: "continent" } },
          { kind: "Field", name: { kind: "Name", value: "country" } },
          { kind: "Field", name: { kind: "Name", value: "createdUtc" } },
          { kind: "Field", name: { kind: "Name", value: "currency" } },
          { kind: "Field", name: { kind: "Name", value: "customOrder" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalCompleteness" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalGrowthRate" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalRating" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTeamStrength" } },
          { kind: "Field", name: { kind: "Name", value: "dealroomSignalTiming" } },
          { kind: "Field", name: { kind: "Name", value: "employees" } },
          { kind: "Field", name: { kind: "Name", value: "employeesChart" } },
          { kind: "Field", name: { kind: "Name", value: "employeesLatest" } },
          { kind: "Field", name: { kind: "Name", value: "fundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "growthChart" } },
          { kind: "Field", name: { kind: "Name", value: "hasPromisingFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasStrongFounder" } },
          { kind: "Field", name: { kind: "Name", value: "hasSuperFounder" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "image" } },
          { kind: "Field", name: { kind: "Name", value: "industries" } },
          { kind: "Field", name: { kind: "Name", value: "isActivelyFundraising" } },
          { kind: "Field", name: { kind: "Name", value: "isBasecampFunded" } },
          { kind: "Field", name: { kind: "Name", value: "isFoundingLocation" } },
          { kind: "Field", name: { kind: "Name", value: "isNpa" } },
          { kind: "Field", name: { kind: "Name", value: "isOpCompany" } },
          { kind: "Field", name: { kind: "Name", value: "isTrCompany" } },
          { kind: "Field", name: { kind: "Name", value: "lastCalculatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "lastFundingDate" } },
          { kind: "Field", name: { kind: "Name", value: "lastUpdatedUtc" } },
          { kind: "Field", name: { kind: "Name", value: "launchYear" } },
          { kind: "Field", name: { kind: "Name", value: "linkedinUrl" } },
          { kind: "Field", name: { kind: "Name", value: "monthsFromLastFunding" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "nodeId" } },
          { kind: "Field", name: { kind: "Name", value: "relativeRunway" } },
          { kind: "Field", name: { kind: "Name", value: "runwayLastUpdate" } },
          { kind: "Field", name: { kind: "Name", value: "runwayEndDate" } },
          { kind: "Field", name: { kind: "Name", value: "tagline" } },
          { kind: "Field", name: { kind: "Name", value: "tags" } },
          { kind: "Field", name: { kind: "Name", value: "targetFund" } },
          { kind: "Field", name: { kind: "Name", value: "techStackPredictleads" } },
          { kind: "Field", name: { kind: "Name", value: "technologies" } },
          { kind: "Field", name: { kind: "Name", value: "totalFunding" } },
          { kind: "Field", name: { kind: "Name", value: "totalFundingSource" } },
          { kind: "Field", name: { kind: "Name", value: "twitterUrl" } },
          { kind: "Field", name: { kind: "Name", value: "websiteTrafficChart" } },
          { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSearchCompaniesLocalGlobeQuery__
 *
 * To run a query within a React component, call `useSearchCompaniesLocalGlobeQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompaniesLocalGlobeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompaniesLocalGlobeQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchCompaniesLocalGlobeQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchCompaniesLocalGlobeQuery, SearchCompaniesLocalGlobeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchCompaniesLocalGlobeQuery, SearchCompaniesLocalGlobeQueryVariables>(
    SearchCompaniesLocalGlobeDocument,
    options,
  );
}
export function useSearchCompaniesLocalGlobeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchCompaniesLocalGlobeQuery, SearchCompaniesLocalGlobeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchCompaniesLocalGlobeQuery, SearchCompaniesLocalGlobeQueryVariables>(
    SearchCompaniesLocalGlobeDocument,
    options,
  );
}
export function useSearchCompaniesLocalGlobeSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    SearchCompaniesLocalGlobeQuery,
    SearchCompaniesLocalGlobeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchCompaniesLocalGlobeQuery, SearchCompaniesLocalGlobeQueryVariables>(
    SearchCompaniesLocalGlobeDocument,
    options,
  );
}
export type SearchCompaniesLocalGlobeQueryHookResult = ReturnType<typeof useSearchCompaniesLocalGlobeQuery>;
export type SearchCompaniesLocalGlobeLazyQueryHookResult = ReturnType<typeof useSearchCompaniesLocalGlobeLazyQuery>;
export type SearchCompaniesLocalGlobeSuspenseQueryHookResult = ReturnType<
  typeof useSearchCompaniesLocalGlobeSuspenseQuery
>;
export type SearchCompaniesLocalGlobeQueryResult = Apollo.QueryResult<
  SearchCompaniesLocalGlobeQuery,
  SearchCompaniesLocalGlobeQueryVariables
>;
export const SearchCompaniesDealroomDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "SearchCompaniesDealroom" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "DealroomCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "results" },
            name: { kind: "Name", value: "dealroomCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "NAME_FUZZY_ASC" }] },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "image" } },
                      { kind: "Field", name: { kind: "Name", value: "path" } },
                      { kind: "Field", name: { kind: "Name", value: "industries" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "about" } },
                      { kind: "Field", name: { kind: "Name", value: "tagline" } },
                      { kind: "Field", name: { kind: "Name", value: "websiteUrl" } },
                      { kind: "Field", name: { kind: "Name", value: "hqLocations" } },
                      { kind: "Field", name: { kind: "Name", value: "team" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useSearchCompaniesDealroomQuery__
 *
 * To run a query within a React component, call `useSearchCompaniesDealroomQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCompaniesDealroomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCompaniesDealroomQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useSearchCompaniesDealroomQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchCompaniesDealroomQuery, SearchCompaniesDealroomQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchCompaniesDealroomQuery, SearchCompaniesDealroomQueryVariables>(
    SearchCompaniesDealroomDocument,
    options,
  );
}
export function useSearchCompaniesDealroomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchCompaniesDealroomQuery, SearchCompaniesDealroomQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchCompaniesDealroomQuery, SearchCompaniesDealroomQueryVariables>(
    SearchCompaniesDealroomDocument,
    options,
  );
}
export function useSearchCompaniesDealroomSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<SearchCompaniesDealroomQuery, SearchCompaniesDealroomQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SearchCompaniesDealroomQuery, SearchCompaniesDealroomQueryVariables>(
    SearchCompaniesDealroomDocument,
    options,
  );
}
export type SearchCompaniesDealroomQueryHookResult = ReturnType<typeof useSearchCompaniesDealroomQuery>;
export type SearchCompaniesDealroomLazyQueryHookResult = ReturnType<typeof useSearchCompaniesDealroomLazyQuery>;
export type SearchCompaniesDealroomSuspenseQueryHookResult = ReturnType<typeof useSearchCompaniesDealroomSuspenseQuery>;
export type SearchCompaniesDealroomQueryResult = Apollo.QueryResult<
  SearchCompaniesDealroomQuery,
  SearchCompaniesDealroomQueryVariables
>;
export const MeetThePortfolioDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "MeetThePortfolio" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "OpsFundFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opsFunds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "mtp" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "opsCompanyfundsByFundId" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "navFundSize" },
                                    name: { kind: "Name", value: "currentNavFromFund" },
                                  },
                                  {
                                    kind: "Field",
                                    alias: { kind: "Name", value: "evReturnFund" },
                                    name: { kind: "Name", value: "evAsFundReturner" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "company" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                                        { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
                                        { kind: "Field", name: { kind: "Name", value: "totalNav" } },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "srcCompany" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "company" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "Field", name: { kind: "Name", value: "id" } },
                                                    { kind: "Field", name: { kind: "Name", value: "badges" } },
                                                    { kind: "Field", name: { kind: "Name", value: "round" } },
                                                    { kind: "Field", name: { kind: "Name", value: "industries" } },
                                                    { kind: "Field", name: { kind: "Name", value: "city" } },
                                                    { kind: "Field", name: { kind: "Name", value: "country" } },
                                                    { kind: "Field", name: { kind: "Name", value: "address" } },
                                                    { kind: "Field", name: { kind: "Name", value: "employeesLatest" } },
                                                    {
                                                      kind: "Field",
                                                      alias: { kind: "Name", value: "rating" },
                                                      name: { kind: "Name", value: "dealroomSignalRating" },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      alias: { kind: "Name", value: "latestMetrics" },
                                                      name: {
                                                        kind: "Name",
                                                        value: "lgCompanyMetricsLatestByCompanyId",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          { kind: "Field", name: { kind: "Name", value: "data" } },
                                                          { kind: "Field", name: { kind: "Name", value: "asAtDate" } },
                                                        ],
                                                      },
                                                    },
                                                    { kind: "Field", name: { kind: "Name", value: "name" } },
                                                    { kind: "Field", name: { kind: "Name", value: "last3Sentiment" } },
                                                    { kind: "Field", name: { kind: "Name", value: "image" } },
                                                    { kind: "Field", name: { kind: "Name", value: "tags" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mtpCompanyfundsByMtpFundId" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "breakeven" } },
                                  { kind: "Field", name: { kind: "Name", value: "capitalConcentration" } },
                                  { kind: "Field", name: { kind: "Name", value: "companyName" } },
                                  { kind: "Field", name: { kind: "Name", value: "companyType" } },
                                  { kind: "Field", name: { kind: "Name", value: "navFundSize" } },
                                  { kind: "Field", name: { kind: "Name", value: "evReturnFund" } },
                                  { kind: "Field", name: { kind: "Name", value: "revenue" } },
                                  { kind: "Field", name: { kind: "Name", value: "yoyGrowth" } },
                                  { kind: "Field", name: { kind: "Name", value: "margin" } },
                                  { kind: "Field", name: { kind: "Name", value: "followOnCapital" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCashFlow" } },
                                  { kind: "Field", name: { kind: "Name", value: "fte" } },
                                  { kind: "Field", name: { kind: "Name", value: "freeCashFlow" } },
                                  { kind: "Field", name: { kind: "Name", value: "runway" } },
                                  { kind: "Field", name: { kind: "Name", value: "fund" } },
                                  { kind: "Field", name: { kind: "Name", value: "followOnCapital" } },
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "margin" } },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "mtpCompany" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        { kind: "Field", name: { kind: "Name", value: "id" } },
                                        { kind: "Field", name: { kind: "Name", value: "badges" } },
                                        { kind: "Field", name: { kind: "Name", value: "round" } },
                                        { kind: "Field", name: { kind: "Name", value: "industries" } },
                                        { kind: "Field", name: { kind: "Name", value: "city" } },
                                        { kind: "Field", name: { kind: "Name", value: "country" } },
                                        { kind: "Field", name: { kind: "Name", value: "address" } },
                                        {
                                          kind: "Field",
                                          alias: { kind: "Name", value: "rating" },
                                          name: { kind: "Name", value: "dealroomSignalRating" },
                                        },
                                        {
                                          kind: "Field",
                                          alias: { kind: "Name", value: "latestMetrics" },
                                          name: { kind: "Name", value: "lgCompanyMetricsLatestByCompanyId" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              { kind: "Field", name: { kind: "Name", value: "data" } },
                                              { kind: "Field", name: { kind: "Name", value: "asAtDate" } },
                                            ],
                                          },
                                        },
                                        { kind: "Field", name: { kind: "Name", value: "name" } },
                                        { kind: "Field", name: { kind: "Name", value: "image" } },
                                        { kind: "Field", name: { kind: "Name", value: "tags" } },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useMeetThePortfolioQuery__
 *
 * To run a query within a React component, call `useMeetThePortfolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetThePortfolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetThePortfolioQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMeetThePortfolioQuery(
  baseOptions?: Apollo.QueryHookOptions<MeetThePortfolioQuery, MeetThePortfolioQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeetThePortfolioQuery, MeetThePortfolioQueryVariables>(MeetThePortfolioDocument, options);
}
export function useMeetThePortfolioLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeetThePortfolioQuery, MeetThePortfolioQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeetThePortfolioQuery, MeetThePortfolioQueryVariables>(MeetThePortfolioDocument, options);
}
export function useMeetThePortfolioSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<MeetThePortfolioQuery, MeetThePortfolioQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeetThePortfolioQuery, MeetThePortfolioQueryVariables>(
    MeetThePortfolioDocument,
    options,
  );
}
export type MeetThePortfolioQueryHookResult = ReturnType<typeof useMeetThePortfolioQuery>;
export type MeetThePortfolioLazyQueryHookResult = ReturnType<typeof useMeetThePortfolioLazyQuery>;
export type MeetThePortfolioSuspenseQueryHookResult = ReturnType<typeof useMeetThePortfolioSuspenseQuery>;
export type MeetThePortfolioQueryResult = Apollo.QueryResult<MeetThePortfolioQuery, MeetThePortfolioQueryVariables>;
export const OpsCompaniesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OpsCompanies" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "limit" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "offset" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opsCompanies" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: { kind: "Variable", name: { kind: "Name", value: "limit" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: { kind: "Variable", name: { kind: "Name", value: "offset" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "srcCompany" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "opsCompanyAsSource" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "opsCompanyfundsByCompanyId" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: { kind: "Name", value: "nodes" },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    { kind: "FragmentSpread", name: { kind: "Name", value: "Fund" } },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
                      { kind: "Field", name: { kind: "Name", value: "totalNav" } },
                      { kind: "Field", name: { kind: "Name", value: "investmentType" } },
                      { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                      { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Fund" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfund" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "announced" } },
          { kind: "Field", name: { kind: "Name", value: "braggy" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutDate" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "coInvestors" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "company" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "srcCompany" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tagline" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "totalNav" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                { kind: "Field", name: { kind: "Name", value: "isFocus" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentEv" } },
          { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentNav" } },
          { kind: "Field", name: { kind: "Name", value: "currentNavFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fund" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "parentFund" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "fundId" } },
          { kind: "Field", name: { kind: "Name", value: "goingInOwnership" } },
          { kind: "Field", name: { kind: "Name", value: "investmentType" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementScore" } },
          { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
          { kind: "Field", name: { kind: "Name", value: "evAsFundReturner" } },
          { kind: "Field", name: { kind: "Name", value: "mtpEv" } },
          { kind: "Field", name: { kind: "Name", value: "investedCapitalFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
          { kind: "Field", name: { kind: "Name", value: "mostRecentRound" } },
          { kind: "Field", name: { kind: "Name", value: "grossMargins" } },
          { kind: "Field", name: { kind: "Name", value: "lastRound" } },
          { kind: "Field", name: { kind: "Name", value: "revenue" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOpsCompaniesQuery__
 *
 * To run a query within a React component, call `useOpsCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpsCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpsCompaniesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useOpsCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<OpsCompaniesQuery, OpsCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpsCompaniesQuery, OpsCompaniesQueryVariables>(OpsCompaniesDocument, options);
}
export function useOpsCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OpsCompaniesQuery, OpsCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpsCompaniesQuery, OpsCompaniesQueryVariables>(OpsCompaniesDocument, options);
}
export function useOpsCompaniesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OpsCompaniesQuery, OpsCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OpsCompaniesQuery, OpsCompaniesQueryVariables>(OpsCompaniesDocument, options);
}
export type OpsCompaniesQueryHookResult = ReturnType<typeof useOpsCompaniesQuery>;
export type OpsCompaniesLazyQueryHookResult = ReturnType<typeof useOpsCompaniesLazyQuery>;
export type OpsCompaniesSuspenseQueryHookResult = ReturnType<typeof useOpsCompaniesSuspenseQuery>;
export type OpsCompaniesQueryResult = Apollo.QueryResult<OpsCompaniesQuery, OpsCompaniesQueryVariables>;
export const OpsFundsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "OpsFunds" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "filter" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfundFilter" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfundsOrderBy" } },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opsCompanyfunds" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: { kind: "Variable", name: { kind: "Name", value: "filter" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "Variable", name: { kind: "Name", value: "orderBy" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "edges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "node" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [{ kind: "FragmentSpread", name: { kind: "Name", value: "Fund" } }],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "Fund" },
      typeCondition: { kind: "NamedType", name: { kind: "Name", value: "OpsCompanyfund" } },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "announced" } },
          { kind: "Field", name: { kind: "Name", value: "braggy" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutDate" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "coInvestors" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "companyId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "company" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "srcCompany" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "company" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "image" } },
                            { kind: "Field", name: { kind: "Name", value: "name" } },
                            { kind: "Field", name: { kind: "Name", value: "tagline" } },
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitement" } },
                            { kind: "Field", name: { kind: "Name", value: "latestExcitementDate" } },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "totalNav" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
                { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
                { kind: "Field", name: { kind: "Name", value: "initialInvestmentRatio" } },
                { kind: "Field", name: { kind: "Name", value: "isFocus" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "cocMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentEv" } },
          { kind: "Field", name: { kind: "Name", value: "currentMultiple" } },
          { kind: "Field", name: { kind: "Name", value: "currentNav" } },
          { kind: "Field", name: { kind: "Name", value: "currentNavFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "currentOwned" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "fund" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "parentFund" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "fundId" } },
          { kind: "Field", name: { kind: "Name", value: "goingInOwnership" } },
          { kind: "Field", name: { kind: "Name", value: "investmentType" } },
          { kind: "Field", name: { kind: "Name", value: "latestExcitementScore" } },
          { kind: "Field", name: { kind: "Name", value: "initialInvestment" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
          { kind: "Field", name: { kind: "Name", value: "evAsFundReturner" } },
          { kind: "Field", name: { kind: "Name", value: "mtpEv" } },
          { kind: "Field", name: { kind: "Name", value: "investedCapitalFromFund" } },
          { kind: "Field", name: { kind: "Name", value: "unrealisedValue" } },
          { kind: "Field", name: { kind: "Name", value: "mostRecentRound" } },
          { kind: "Field", name: { kind: "Name", value: "grossMargins" } },
          { kind: "Field", name: { kind: "Name", value: "lastRound" } },
          { kind: "Field", name: { kind: "Name", value: "revenue" } },
          { kind: "Field", name: { kind: "Name", value: "cashoutGroup" } },
          { kind: "Field", name: { kind: "Name", value: "totalInvestedCapital" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useOpsFundsQuery__
 *
 * To run a query within a React component, call `useOpsFundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOpsFundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpsFundsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useOpsFundsQuery(baseOptions?: Apollo.QueryHookOptions<OpsFundsQuery, OpsFundsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OpsFundsQuery, OpsFundsQueryVariables>(OpsFundsDocument, options);
}
export function useOpsFundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OpsFundsQuery, OpsFundsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OpsFundsQuery, OpsFundsQueryVariables>(OpsFundsDocument, options);
}
export function useOpsFundsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<OpsFundsQuery, OpsFundsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<OpsFundsQuery, OpsFundsQueryVariables>(OpsFundsDocument, options);
}
export type OpsFundsQueryHookResult = ReturnType<typeof useOpsFundsQuery>;
export type OpsFundsLazyQueryHookResult = ReturnType<typeof useOpsFundsLazyQuery>;
export type OpsFundsSuspenseQueryHookResult = ReturnType<typeof useOpsFundsSuspenseQuery>;
export type OpsFundsQueryResult = Apollo.QueryResult<OpsFundsQuery, OpsFundsQueryVariables>;
export const OpportunityActionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "OpportunityAction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "action" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Action" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "actionPayloadInput" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "ActionPayloadInput" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "entity" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Entity" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opportunityAction" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "action" },
                value: { kind: "Variable", name: { kind: "Name", value: "action" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "actionPayloadInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "actionPayloadInput" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "entity" },
                value: { kind: "Variable", name: { kind: "Name", value: "entity" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type OpportunityActionMutationFn = Apollo.MutationFunction<
  OpportunityActionMutation,
  OpportunityActionMutationVariables
>;

/**
 * __useOpportunityActionMutation__
 *
 * To run a mutation, you first call `useOpportunityActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpportunityActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [opportunityActionMutation, { data, loading, error }] = useOpportunityActionMutation({
 *   variables: {
 *      action: // value for 'action'
 *      actionPayloadInput: // value for 'actionPayloadInput'
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function useOpportunityActionMutation(
  baseOptions?: Apollo.MutationHookOptions<OpportunityActionMutation, OpportunityActionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OpportunityActionMutation, OpportunityActionMutationVariables>(
    OpportunityActionDocument,
    options,
  );
}
export type OpportunityActionMutationHookResult = ReturnType<typeof useOpportunityActionMutation>;
export type OpportunityActionMutationResult = Apollo.MutationResult<OpportunityActionMutation>;
export type OpportunityActionMutationOptions = Apollo.BaseMutationOptions<
  OpportunityActionMutation,
  OpportunityActionMutationVariables
>;
export const AddOpportunityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddOpportunity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ManualOpportunityInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opportunityNew" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "manualOpportunityInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type AddOpportunityMutationFn = Apollo.MutationFunction<AddOpportunityMutation, AddOpportunityMutationVariables>;

/**
 * __useAddOpportunityMutation__
 *
 * To run a mutation, you first call `useAddOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOpportunityMutation, { data, loading, error }] = useAddOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<AddOpportunityMutation, AddOpportunityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOpportunityMutation, AddOpportunityMutationVariables>(AddOpportunityDocument, options);
}
export type AddOpportunityMutationHookResult = ReturnType<typeof useAddOpportunityMutation>;
export type AddOpportunityMutationResult = Apollo.MutationResult<AddOpportunityMutation>;
export type AddOpportunityMutationOptions = Apollo.BaseMutationOptions<
  AddOpportunityMutation,
  AddOpportunityMutationVariables
>;
export const UpdateOpportunityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateOpportunity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "opportunityId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UpdateCompanyInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opportunityUpdate" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "opportunityId" },
                value: { kind: "Variable", name: { kind: "Name", value: "opportunityId" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "updateCompanyInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateOpportunityMutationFn = Apollo.MutationFunction<
  UpdateOpportunityMutation,
  UpdateOpportunityMutationVariables
>;

/**
 * __useUpdateOpportunityMutation__
 *
 * To run a mutation, you first call `useUpdateOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpportunityMutation, { data, loading, error }] = useUpdateOpportunityMutation({
 *   variables: {
 *      opportunityId: // value for 'opportunityId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOpportunityMutation, UpdateOpportunityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOpportunityMutation, UpdateOpportunityMutationVariables>(
    UpdateOpportunityDocument,
    options,
  );
}
export type UpdateOpportunityMutationHookResult = ReturnType<typeof useUpdateOpportunityMutation>;
export type UpdateOpportunityMutationResult = Apollo.MutationResult<UpdateOpportunityMutation>;
export type UpdateOpportunityMutationOptions = Apollo.BaseMutationOptions<
  UpdateOpportunityMutation,
  UpdateOpportunityMutationVariables
>;
export const FlagCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "FlagCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "FlagCompanyInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companyFlags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "flagCompanyInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type FlagCompanyMutationFn = Apollo.MutationFunction<FlagCompanyMutation, FlagCompanyMutationVariables>;

/**
 * __useFlagCompanyMutation__
 *
 * To run a mutation, you first call `useFlagCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlagCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flagCompanyMutation, { data, loading, error }] = useFlagCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFlagCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<FlagCompanyMutation, FlagCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FlagCompanyMutation, FlagCompanyMutationVariables>(FlagCompanyDocument, options);
}
export type FlagCompanyMutationHookResult = ReturnType<typeof useFlagCompanyMutation>;
export type FlagCompanyMutationResult = Apollo.MutationResult<FlagCompanyMutation>;
export type FlagCompanyMutationOptions = Apollo.BaseMutationOptions<FlagCompanyMutation, FlagCompanyMutationVariables>;
export const EmailOpportunityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "EmailOpportunity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "EmailOpportunityInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "opportunityEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "emailOpportunityInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type EmailOpportunityMutationFn = Apollo.MutationFunction<
  EmailOpportunityMutation,
  EmailOpportunityMutationVariables
>;

/**
 * __useEmailOpportunityMutation__
 *
 * To run a mutation, you first call `useEmailOpportunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailOpportunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailOpportunityMutation, { data, loading, error }] = useEmailOpportunityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailOpportunityMutation(
  baseOptions?: Apollo.MutationHookOptions<EmailOpportunityMutation, EmailOpportunityMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EmailOpportunityMutation, EmailOpportunityMutationVariables>(
    EmailOpportunityDocument,
    options,
  );
}
export type EmailOpportunityMutationHookResult = ReturnType<typeof useEmailOpportunityMutation>;
export type EmailOpportunityMutationResult = Apollo.MutationResult<EmailOpportunityMutation>;
export type EmailOpportunityMutationOptions = Apollo.BaseMutationOptions<
  EmailOpportunityMutation,
  EmailOpportunityMutationVariables
>;
export const UnAssignUserActionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UnAssignUserAction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UnAssignPayloadInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unAssignUser" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "unAssignPayloadInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UnAssignUserActionMutationFn = Apollo.MutationFunction<
  UnAssignUserActionMutation,
  UnAssignUserActionMutationVariables
>;

/**
 * __useUnAssignUserActionMutation__
 *
 * To run a mutation, you first call `useUnAssignUserActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnAssignUserActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unAssignUserActionMutation, { data, loading, error }] = useUnAssignUserActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnAssignUserActionMutation(
  baseOptions?: Apollo.MutationHookOptions<UnAssignUserActionMutation, UnAssignUserActionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnAssignUserActionMutation, UnAssignUserActionMutationVariables>(
    UnAssignUserActionDocument,
    options,
  );
}
export type UnAssignUserActionMutationHookResult = ReturnType<typeof useUnAssignUserActionMutation>;
export type UnAssignUserActionMutationResult = Apollo.MutationResult<UnAssignUserActionMutation>;
export type UnAssignUserActionMutationOptions = Apollo.BaseMutationOptions<
  UnAssignUserActionMutation,
  UnAssignUserActionMutationVariables
>;
export const TopInvestorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "TopInvestors" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "lgInvestors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lgRanking" },
                      value: {
                        kind: "ObjectValue",
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "isNull" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "ListValue", values: [{ kind: "EnumValue", value: "LG_RANKING_ASC" }] },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "lgRanking" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useTopInvestorsQuery__
 *
 * To run a query within a React component, call `useTopInvestorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopInvestorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopInvestorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTopInvestorsQuery(
  baseOptions?: Apollo.QueryHookOptions<TopInvestorsQuery, TopInvestorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TopInvestorsQuery, TopInvestorsQueryVariables>(TopInvestorsDocument, options);
}
export function useTopInvestorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TopInvestorsQuery, TopInvestorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TopInvestorsQuery, TopInvestorsQueryVariables>(TopInvestorsDocument, options);
}
export function useTopInvestorsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<TopInvestorsQuery, TopInvestorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TopInvestorsQuery, TopInvestorsQueryVariables>(TopInvestorsDocument, options);
}
export type TopInvestorsQueryHookResult = ReturnType<typeof useTopInvestorsQuery>;
export type TopInvestorsLazyQueryHookResult = ReturnType<typeof useTopInvestorsLazyQuery>;
export type TopInvestorsSuspenseQueryHookResult = ReturnType<typeof useTopInvestorsSuspenseQuery>;
export type TopInvestorsQueryResult = Apollo.QueryResult<TopInvestorsQuery, TopInvestorsQueryVariables>;
export const SubmitFeedbackDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SubmitFeedback" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UserFeedbackInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "submitFeedback" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userFeedbackInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SubmitFeedbackMutationFn = Apollo.MutationFunction<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>;

/**
 * __useSubmitFeedbackMutation__
 *
 * To run a mutation, you first call `useSubmitFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFeedbackMutation, { data, loading, error }] = useSubmitFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SubmitFeedbackMutation, SubmitFeedbackMutationVariables>(SubmitFeedbackDocument, options);
}
export type SubmitFeedbackMutationHookResult = ReturnType<typeof useSubmitFeedbackMutation>;
export type SubmitFeedbackMutationResult = Apollo.MutationResult<SubmitFeedbackMutation>;
export type SubmitFeedbackMutationOptions = Apollo.BaseMutationOptions<
  SubmitFeedbackMutation,
  SubmitFeedbackMutationVariables
>;
export const TagUserCompanyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "TagUserCompany" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "TagCompanyInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userCompanyTags" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "tagCompanyInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type TagUserCompanyMutationFn = Apollo.MutationFunction<TagUserCompanyMutation, TagUserCompanyMutationVariables>;

/**
 * __useTagUserCompanyMutation__
 *
 * To run a mutation, you first call `useTagUserCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagUserCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagUserCompanyMutation, { data, loading, error }] = useTagUserCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagUserCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<TagUserCompanyMutation, TagUserCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<TagUserCompanyMutation, TagUserCompanyMutationVariables>(TagUserCompanyDocument, options);
}
export type TagUserCompanyMutationHookResult = ReturnType<typeof useTagUserCompanyMutation>;
export type TagUserCompanyMutationResult = Apollo.MutationResult<TagUserCompanyMutation>;
export type TagUserCompanyMutationOptions = Apollo.BaseMutationOptions<
  TagUserCompanyMutation,
  TagUserCompanyMutationVariables
>;
export const UploadCompanyAttachmentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UploadCompanyAttachment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "path" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "companyAttachmentUpload" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "companyAttachmentInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "companyId" },
                      value: { kind: "Variable", name: { kind: "Name", value: "companyId" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: { kind: "Variable", name: { kind: "Name", value: "name" } },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "filePath" },
                      value: { kind: "Variable", name: { kind: "Name", value: "path" } },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UploadCompanyAttachmentMutationFn = Apollo.MutationFunction<
  UploadCompanyAttachmentMutation,
  UploadCompanyAttachmentMutationVariables
>;

/**
 * __useUploadCompanyAttachmentMutation__
 *
 * To run a mutation, you first call `useUploadCompanyAttachmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyAttachmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompanyAttachmentMutation, { data, loading, error }] = useUploadCompanyAttachmentMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useUploadCompanyAttachmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadCompanyAttachmentMutation, UploadCompanyAttachmentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadCompanyAttachmentMutation, UploadCompanyAttachmentMutationVariables>(
    UploadCompanyAttachmentDocument,
    options,
  );
}
export type UploadCompanyAttachmentMutationHookResult = ReturnType<typeof useUploadCompanyAttachmentMutation>;
export type UploadCompanyAttachmentMutationResult = Apollo.MutationResult<UploadCompanyAttachmentMutation>;
export type UploadCompanyAttachmentMutationOptions = Apollo.BaseMutationOptions<
  UploadCompanyAttachmentMutation,
  UploadCompanyAttachmentMutationVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Node: [
      "Country",
      "DealroomCompany",
      "DealroomCompanyInvestor",
      "DealroomCompanyTeam",
      "DealroomFounder",
      "DealroomFundingRound",
      "DealroomFundingRoundInvestor",
      "DealroomInvestor",
      "DealroomNews",
      "Gpt41Category",
      "LgCategory",
      "LgCompany",
      "LgCompanyComment",
      "LgCompanyFile",
      "LgCompanyFlag",
      "LgCompanyInvestor",
      "LgCompanyMetric",
      "LgCompanyMetricsLatest",
      "LgCompanyNote",
      "LgCompanySentimentAnalytic",
      "LgCompanyTeam",
      "LgFile",
      "LgFounder",
      "LgFundingRound",
      "LgFundingRoundInvestor",
      "LgInvestor",
      "LgMember",
      "LgMemberSetting",
      "LgUserFeedback",
      "MnEntry",
      "MtpCompanyfund",
      "NzrNotification",
      "NzrOpportunity",
      "NzrOpportunityEmail",
      "NzrOpportunityEmailCandidate",
      "NzrSentimentForm",
      "NzrSentimentFormAssignee",
      "NzrUserCompanyTag",
      "OpsCompany",
      "OpsCompanyfund",
      "OpsCompanyfundcapital",
      "OpsFund",
      "OpsPerson",
      "Query",
      "Rule",
      "Signal",
      "SrcCompany",
      "SrcCompanyInvestor",
      "SrcCompanyTeam",
      "SrcConfig",
      "SrcFounder",
      "SrcFundingRound",
      "SrcFundingRoundInvestor",
      "SrcInvestor",
      "TfForm",
      "TfWorkspace",
      "TrelloBoard",
      "TrelloCard",
      "TrelloLabel",
      "TrelloList",
      "TrelloMember",
      "UserReport",
      "UserRole",
    ],
  },
};
export default result;
